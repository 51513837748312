/* eslint-disable eqeqeq */
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import { FadeLoader } from 'react-spinners';

function ModifierContact() {
    const [spinnerButton, SetSpinnerButton] = useState(false)

    const { id } = useParams();
    const [address, Setaddress] = useState('')
    const [animationClassAddress, setanimationClassAddress] = useState('');

    const [tele, Settele] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');


    const [isEmail, SetisEmail] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;

    const [pending, setPending] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (address.trim() == '') {
            toast.warning("L'adress physique est obligatoire", {
                autoClose: 2000
            });
            setanimationClassAddress('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setanimationClassAddress(' ')
            }, 3000)
            return;
        } else if (tele.trim() == '') {
            toast.warning('Le téléphone est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            return;
        }
        else if (isEmail.trim() == '') {
            toast.warning("L'address email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            return;
        }
        else if (!isEmail.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX border-red-600 text-red-600')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            return;
        }

        SetSpinnerButton(true);
        axios.put(`https://irakoze.burundientempsreel.com/api/updatecoordonne/${id}`, {
            adress: address,
            tel: tele,
            email: isEmail
        }).then((response) => {
            Setaddress('');
            SetisEmail('');
            Settele('')
            toast.success('La modification a été faite correctement');
        }).catch((error) => {
            console.error(error.message);
        }).finally(() => {
            SetSpinnerButton(false);
        });;;
    }

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/findcoordonne/${id}`)
            .then((response) => {
                Setaddress(response.data.adress);
                SetisEmail(response.data.email);
                Settele(response.data.tel)
                setTimeout(() => {
                    setPending(false)
                }, 100)
            }).catch((error) => {
                console.error(error.message);
            });
    }, [id])


    return (
        <div className=' bg-white  min-h-[75vh] flex justify-center items-center'>
            {pending && <Loading />}
            <div className="bg-white border border-orange-600 rounded-2xl m-3 p-4  w-[30em] h-max wimax ">
                <h2 className=' p-2 text-center  text-[30px] text-orange-500 animeBorder'>Modifier le contact</h2>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col mb-6  p-1">
                        <div className="flex w-full justify-between flex-col my-3">
                            <div class="relative h-11 w-full bloksInput mr-2  my-2">
                                <input value={address} onInput={(e) => Setaddress(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassAddress}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    L'adress physique
                                </label>
                            </div>

                            <div class="relative h-11 w-full bloksInput mr-2  my-2">
                                <input value={tele} onInput={(e) => Settele(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Le téléphone
                                </label>
                            </div>
                        </div>

                        <div className="flex w-full justify-between responsivLogin my-2 ">

                            <div class="relative h-11 w-full bloksInput mr-2 ">
                                <input value={isEmail} onInput={(e) => SetisEmail(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Email
                                </label>
                            </div>
                        </div>
                    </div>

                    {spinnerButton ? (
                        <>
                            <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80">
                                <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                                    <FadeLoader
                                        color="rgb(255, 255, 255)"
                                        height={10}
                                        margin={-9}
                                        radius={100}
                                        speedMultiplier={1}
                                        width={1}
                                    /></div>
                                <input type="submit" id="send" value="Modifier" class=" transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex justify-end items-center mt-2">
                                <label htmlFor='sende' className="w-max cursor-pointer  flex justify-end p-1 bg-orange-600 rounded  px-2 py-1 text-white">
                                    <input type="submit" id='sende' value="Modifier" />
                                    <i title="Modifier" class="bi bi-send ml-2 "></i>
                                </label>
                            </div>

                        </>
                    )}
                </form>
            </div>
        </div>
    )
}

export default ModifierContact
