/* eslint-disable eqeqeq */
import axios from 'axios';
import React, { useRef } from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners';

function RegisterAdmin() {

    const [spinnerButton, SetSpinnerButton] = useState(false)

    const history = useNavigate();
    const [isNom, SetisNom] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const nomRef = useRef(null)

    const [isPrenom, SetisPrenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const prenomRef = useRef(null)

    const [isEmail, SetisEmail] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const emailRef = useRef(null)

    const [isTel, SetisTel] = useState('')
    const [animationClassTel, setAnimationClassTel] = useState('');
    const telRef = useRef(null)

    const [isPass, SetisPass] = useState('')
    const [animationClassisPass, setAnimationClassPass] = useState('');
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [passwordVisible, setPasswordVisible] = useState(false);
    const passRef = useRef(null)


    const [isPassConfirm, SetisPassConfirm] = useState('')
    const [animationClassisConfirm, setAnimationClassConfirm] = useState('');
    const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);
    const passConfirmRef = useRef(null)

    const [radioValue, setRadioValue] = useState('');

    const [isProfil, SetisProfil] = useState('')



    const handleSubmit = (e) => {
        e.preventDefault()
        if (isNom.trim() == '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            nomRef.current.focus()
            return;
        } else if (isPrenom.trim() == '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            prenomRef.current.focus()
            return;
        }
        else if (isTel.trim() == '') {
            toast.warning('Le numero de téléphone est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassTel(' ')
            }, 3000)
            telRef.current.focus()
            return;
        }
        else if (isEmail.trim() == '') {
            toast.warning("L'address email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            emailRef.current.focus()
            return;
        }
        else if (!isEmail.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            emailRef.current.focus()
            return;
        }
        else if (isPass.trim() == '') {
            toast.warning('Le  password est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPass(' ')
            }, 3000)
            passRef.current.focus()
            return;
        }
        else if (!isPass.trim().match(PasswordRegex)) {
            toast.error(`Le mot de passe doit contenir au moins une lettre minuscule,une lettre majuscule, un chiffre et uncaractère spécial`, {
                autoClose: 10000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPass(' ')
            }, 3000)
            passRef.current.focus()
            return;
        }
        else if (isPassConfirm.trim() == '') {
            toast.warning('Confirmer le mot de passe', {
                autoClose: 2000
            });
            setAnimationClassConfirm('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassConfirm(' ')
            }, 3000)
            passConfirmRef.current.focus()
            return;
        } else if (isPassConfirm.trim() != isPass.trim()) {
            toast.error('La confirmation de mot de passe est incorrect,Veuillez reessayer encore !!', {
                autoClose: 5000
            });
            setAnimationClassConfirm('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassConfirm(' ')
            }, 3000)
            passConfirmRef.current.focus()
            return;
        } else if (radioValue === '') {

            toast.warning('Veuillez sélectionner votre genre', {
                autoClose: 2000
            });
            return;
        }
        else if (isProfil == '') {

            toast.warning('Veuillez sélectionner votre photos de profil', {
                autoClose: 2000
            });
            return;
        }
        SetSpinnerButton(true)
        const formData = new FormData();
        formData.append('nom', isNom);
        formData.append('prenom', isPrenom);
        formData.append('email', isEmail);
        formData.append('tel', isTel);
        formData.append('genre', radioValue);
        formData.append('password', isPass);
        formData.append('photo', isProfil);
        axios.post('https://irakoze.burundientempsreel.com/api/user/register', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            if (response.data.error) {
                toast.warning(response.data.error, {
                    autoClose: 7000
                });


            } else {
                history('/Login');

            }
        }).catch((error) => {

            console.log("Error message:", error.message);
            if (error.response) {
                toast.warning(error.response.data.error, {
                    autoClose: 2000
                });

            }
        }).finally(() => {
            SetSpinnerButton(false);

        });

    }
    const sendRef = useRef(null)


    return (
        <div className='h-full flex justify-center items-center'>
            <div className="bg-white temoign border border-orange-600  m-3 p-2 rounded w-[30em] h-max wimax ">
                <h2 className=' p-2 text-center  text-[30px] text-orange-500'>Inscription</h2>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col mb-6  p-1">
                        <div className="flex w-full justify-between responsivLogin my-3">
                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={nomRef} value={isNom} onInput={(e) => SetisNom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Nom
                                </label>
                            </div>

                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={prenomRef} value={isPrenom} onInput={(e) => SetisPrenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Prénom
                                </label>
                            </div>
                        </div>

                        <div className="flex w-full justify-between responsivLogin ">

                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={telRef} value={isTel} onInput={(e) => SetisTel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Téléphone
                                </label>
                            </div>

                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={emailRef} value={isEmail} onInput={(e) => SetisEmail(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Email
                                </label>
                            </div>
                        </div>

                        <div className="flex w-full justify-between responsive31_temoign separeNamePrenom my-3">
                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <div className="flex items-center w-full ">
                                    <div className={`flex items-center w-full -400 relative`}>
                                        <div class="relative h-11 w-full mb-6 bloksInput mr-2 ">
                                            <input ref={passRef} value={isPass} onInput={(e) => SetisPass(e.target.value)} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassisPass}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Mot de passe
                                            </label>
                                        </div>
                                        <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] pb-2 absolute flex justify-center items-center right-2 ">
                                            <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <div className="flex items-center w-full ">
                                    <div className={`flex items-center w-full -400 relative`}>
                                        <div class="relative h-11 w-full mb-6 bloksInput mr-2 ">
                                            <input ref={passConfirmRef} value={isPassConfirm} onInput={(e) => SetisPassConfirm(e.target.value)} type={passwordVisibleConfirm ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassisConfirm}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Confirmer le mot de passe
                                            </label>
                                        </div>
                                        <div onClick={() => { setPasswordVisibleConfirm(!passwordVisibleConfirm) }} className=" cursor-pointer w-[20px] h-[20px] pb-2 absolute flex justify-center items-center right-2 ">
                                            <i class={`bi ${passwordVisibleConfirm ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-flex gap-10 responsivLogin ">
                            <p className="inline-flex items-center text-[18px] text-gray-500"> Votre genre:</p>
                            <div className="genre relative">
                                <div className="inline-flex items-center  relative right-10">
                                    <label className="relative flex cursor-pointer items-center rounded-full p-3" htmlFor="html" data-ripple-dark="true">
                                        <input id="Masculin" name="genre" type="radio" checked={radioValue === 'Masculin'} onChange={(e) => { setRadioValue(e.target.value) }} value='Masculin' className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-pink-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-pink-500 checked:before:bg-pink-500 hover:before:opacity-10" />
                                        <div className="pointer-events-none absolute top-[50%] left-[51%] flex justify-center items-center -translate-y-2/4 -translate-x-2/4 text-pink-500 opacity-0 transition-opacity peer-checked:opacity-100">
                                            <i className="bi bi-stop-circle-fill " />
                                        </div>
                                    </label>
                                    <label className="mt-px cursor-pointer select-none font-light text-gray-700" htmlFor="Masculin">
                                        Masculin
                                    </label>
                                </div>
                                <div className="inline-flex items-center relative right-10">
                                    <label className="relative flex cursor-pointer items-center rounded-full p-3" htmlFor="react" data-ripple-dark="true">
                                        <input id="Feminin" name="genre" type="radio" checked={radioValue === 'Féminin'} onChange={(e) => { setRadioValue(e.target.value) }} value='Féminin' className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-pink-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-pink-500 checked:before:bg-pink-500 hover:before:opacity-10" />
                                        <div className="pointer-events-none absolute top-[50%] left-[51%] flex justify-center items-center -translate-y-2/4 -translate-x-2/4 text-pink-500 opacity-0 transition-opacity peer-checked:opacity-100">
                                            <i className="bi bi-stop-circle-fill " />
                                        </div>
                                    </label>
                                    <label className="mt-px cursor-pointer select-none font-light text-gray-700" htmlFo="Feminin">
                                        Féminin
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="relative top-6">
                            <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
                            <label htmlFor="profils" className='transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white'>Selection le profile</label>
                            <input type="file" id='profils' name='photo' hidden accept='image/*' onChange={(e) => { const url = (e.target.files[0]); SetisProfil(url) }} />
                            <div className={`animeBorder p-1 transition-all relative  top-4 left-20 rounded border-4 border-dotted border-orange-500 w-[100px] ${isProfil == '' ? 'h-[0px] opacity-0 pointer-events-none' : 'h-[100px] opacity-100 pointer-events-none'}`}>
                                {isProfil && (<img src={URL.createObjectURL(isProfil)} alt="" className='w-full h-full rounded object-cover' />)}

                            </div>
                        </div>
                    </div>
                    <div className='text-gray-700 mt-10'>Vous avez un compte? <Link to='/Login' className='text-blue-600'>Se connecter</Link> </div>
                    <div className="flex relative justify-end items-center">
                        {spinnerButton ? (
                            <>

                                <label htmlFor="send" className="w-max relative pointer-events-none   flex justify-center items-center p-1 bg-gray-900 rounded cursor-pointer px-5 py-1 text-white ">
                                    <div className='absolute bg-transparent pt-5  pl-5 w-full h-full flex justify-center items-center z-50  transition-all  rounded  cursor-pointer px-5 py-1 text-gray-600'>
                                        <FadeLoader
                                            color="rgba(255, 255, 255)"
                                            height={10}
                                            margin={-9}
                                            radius={100}
                                            speedMultiplier={1}
                                            width={1}
                                        /></div>
                                    <input type="submit" id="send" value="Créer" class=" transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>

                                </label>
                            </>
                        ) : (
                            <>
                                <label htmlFor="send" ref={sendRef} className="w-max  flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white ">
                                    <span>Créer</span>
                                    <i title="Créer" className="bi bi-send ml-3 "></i>
                                    <input type="submit" id="send" class=" transition-all hidden rounded  cursor-pointer px-5 py-1 text-gray-600"></input>

                                </label>
                            </>
                        )}


                        <input type="submit" id="send" hidden></input>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterAdmin