import React from 'react'
import NavBarsAnnonces from '../NavBarsAnnonces/NavBarsAnnonces'
import AjouterAnnoncer from './AjouterAnnoncer'
export default function AjouterAnnoncerComponent() {
  return (
    <>
      <NavBarsAnnonces />
      <AjouterAnnoncer />
    </>
  )
}
