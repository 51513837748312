import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function NavBarsRessource() {
    const { pathname } = useLocation()

    let AdminRessources = /^\/Admin\/Ressources.*/
    let AdminRessourcesAjouter = /^\/Admin\/AjouteRessources.*/

    return (
        <div className='bg-gray-200 w-full pt-2 flex justify-around sticky top-0 z-10'>
            {AdminRessources.test(pathname) ? (
                <Link to='/Admin/Ressources' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                    <p className='sizeText'>Géstion des ressources</p>
                </Link>
            ) : (
                <Link to='/Admin/Ressources' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                    <p className='sizeText'>Géstion des ressources</p>
                </Link>
            )}
            {AdminRessourcesAjouter.test(pathname) ? (
                <Link to='/Admin/AjouteRessources' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                    <p className='sizeText'>Ajouter le ressource</p>
                </Link>
            ) : (
                <Link to='/Admin/AjouteRessources' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                    <p className='sizeText'>Ajouter le ressource</p>
                </Link>
            )}
        </div>
    )
}

export default NavBarsRessource
