import React from 'react'
import EvenementNavBars from '../EvenementNavNars/EvenementNavBars'
import AjoutEvenement from './AjoutEvenement'
function AjoutEvenemenComponent() {
    return (
        <>
            <EvenementNavBars /> 
            <AjoutEvenement /> 
        </>
    )
}

export default AjoutEvenemenComponent
