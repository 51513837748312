/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import Loading from '../../../Loading/Loading';

function DetailEventAdmin() {
    const { id } = useParams();
    const [event, setEvent] = useState({});
    const [photo, setPhoto] = useState([]);
    const [isPending, setPending] = useState(true)

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getonsource/${id}`).then((response) => {
            setEvent(response.data);
            setPhoto(response.data.resourcesfiles);
            setTimeout(() => {
                setPending(false)
            }, 100)
        });
    }, [id]);


    //     const extension = filename.split('.').pop();
    //     if (extension === 'pdf') {
    //         console.log("C'est un fichier PDF.");
    //     } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
    //         return (
    //             <video
    //                 className="w-full h-full object-cover"
    //                 controls
    //                 src={`https://irakoze.burundientempsreel.com/uploads/resources/${filename}`}
    //             />
    //         );
    //     } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
    //         return (
    //             <img
    //                 src={`https://irakoze.burundientempsreel.com/uploads/resources/${filename}`}
    //                 className="w-full h-full object-cover"
    //             />
    //         );
    //     }
    // };



    function handleDelete(id, position) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Etes-vous sûr que vous voulez supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'SUPPRIMER',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/resourcefile/ById/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('le fichier a été supprimé avec succès');

                            const allPhotos = [...photo]
                            allPhotos.splice(position, 1)
                            setPhoto(allPhotos)

                        } else {
                            toast.error("Une erreur s'est produite lors de la suppression du fichier");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la suppression du fichier");
                    });
            }
        });
    }

    const [loadingImages, setLoadingImages] = useState([]);
    const [loadingImage, setLoadingImage] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    const [mobile, SetMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 600)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <div className="w-full  min-h-[75vh]">
            {isPending && <Loading />}
            <div className="p-2 w-full  h-max flex shadow1 flex-col">
                {event && (
                    <div className="p-1">
                        <div className="w-fullp h-full mr-1">
                            <div className="h-[70vh] border p-2 border-orange-600 rounded overflow-hidden ">
                                {event.file && (() => {
                                    const extension = event.file.split('.').pop();
                                    if (extension === 'pdf') {

                                        return (
                                            <div>PDF introuvable</div>
                                        );
                                    } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                        return (

                                            <>
                                                <div key={event.id} className='relative border h-full w-full '>

                                                    {loadingImage && (
                                                        <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                    )}

                                                    <ReactPlayer
                                                        url={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                        controls
                                                        className=" inline object-cover object-center w-full h-full"
                                                        onReady={() => {
                                                            setTimeout(() => {
                                                                setLoadingImage(false);
                                                            }, 1000);
                                                        }}
                                                    />


                                                </div>
                                            </>


                                        );
                                    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                        return (
                                            <div className='relative h-full w-full'>
                                                {loadingImage && (
                                                    <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                )}

                                                <img
                                                    src={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                    alt={`Image de ${event.titre}`}
                                                    className={`w-full h-full object-contain`} // Masquer l'image pendant le chargement
                                                    onLoad={() => {
                                                        setTimeout(() => {
                                                            setLoadingImage(false);
                                                        }, 1000);
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })()}
                            </div>
                        </div>
                        <div className="w-full">
                            <h2 className="text-center  text-[25px] y-2">{event.titre}</h2>
                            <p className="text-gray-500">{event.description}</p>
                        </div>
                    </div>
                )}

                <div className="flex flex-wrap justify-center">
                    {(photo.length > 0) ? (
                        <>
                            <div className={`w-full ${mobile ? 'text-[15px]' : 'text-[22px]'} text-left  text-bold mt-10 text-gray-700`}>Les photos ou vidéo supplémentaire</div>

                            {photo.map((photos, position) => {
                                return (
                                    <div className="hoverHideeDelet relative w-[20em] h-[20em] border border-orange-700 rounded overflow-hidden m-2">
                                        <button
                                            onClick={() => handleDelete(photos.id, position)}
                                            className={`shower absolute opacity-0 pointer-events-none z-2  bi bi-trash-fill right-0 bg-red-400 text-[20px] text-white hover:bg-red-600 transition-all w-[30px] h-[30px] flex justify-center items-center cursor-pointer`}
                                        >
                                        </button>
                                        {photos.file && (() => {
                                            const extension = photos.file.split('.').pop();
                                            if (extension === 'pdf') {

                                                return (
                                                    <div>PDF introuvable</div>
                                                );
                                            } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                return (

                                                    <>
                                                        <div key={photos.id} className='relative border h-full w-full '>
                                                            {loadingImages[position] ? (
                                                                <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                            ) :
                                                                (
                                                                    <ReactPlayer
                                                                        url={`https://irakoze.burundientempsreel.com/uploads/resources/${photos.file}`}
                                                                        controls
                                                                        className=" inline object-cover object-center w-full h-full"

                                                                    />
                                                                )}

                                                        </div>
                                                    </>


                                                );
                                            } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                return (
                                                    <div key={photos.id} className='relative  h-full w-full '>
                                                        {loadingImages[position] ? (
                                                            <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                        ) :
                                                            (
                                                                <LoadingImage
                                                                    src={`https://irakoze.burundientempsreel.com/uploads/resources/${photos.file}`}
                                                                    className="w-full h-full border-none object-contain"
                                                                    onLoad={() => handleImageLoad(position)}
                                                                />
                                                            )}

                                                    </div>
                                                );
                                            }
                                            return null;
                                        })()}
                                    </div>
                                )
                            })}
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default DetailEventAdmin;
