/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners'

function AjoutRessourceAdmin() {
  const [spinnerButton, SetSpinnerButton] = useState(false)
  const history = useNavigate();
  const [isTitre, SetisTitre] = useState('')
  const [animationClassTitre, setanimationClassTitre] = useState('');
  const [animationClassProfil, setanimationClassProfil] = useState('');
  const [isTextarea, SetisTextarea] = useState('')
  const [animationClassTextarea, setAnimationClassTextarea] = useState('');
  const [autoScrollHeight, setautoScrollHeight] = useState(50);
  const [valueTextarea, setvalueTextarea] = useState('');
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (isTitre.trim() == '') {
      toast.warning("Titre de l'événement est obligatoire", {
        autoClose: 2000
      });
      setanimationClassTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setanimationClassTitre(' ')
      }, 3000)
      return;
    }
    else if (file === null) {
      toast.warning("Selectionner le fichier qui déscrit d'évenement", {
        autoClose: 2000
      });
      setanimationClassProfil(' animate__animated animate__shakeX bg-red-500')
      setTimeout(() => {
        setanimationClassProfil(' ')
      }, 3000)
      return;
    }
    else if (isTextarea.trim() === '') {
      toast.warning("  La déscription de l'événement est obligatore", {
        autoClose: 2000
      });
      setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setAnimationClassTextarea(' ')
      }, 3000)
      return;
    }
    SetSpinnerButton(true)
    const formData = new FormData();
    formData.append("titre", isTitre);
    formData.append("description", isTextarea);
    formData.append("file", file);
    axios
      .post('https://irakoze.burundientempsreel.com/api/addresources', formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });

        setFile(null);
        SetisTextarea('');
        SetisTitre('');
      })
      .catch((error) => {

        console.error(error.message);
      }).finally(() => {
        SetSpinnerButton(false)
      });
  }
  const [loading, SetLoading] = useState(true)
  const [mobile, SetMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 600)
    }
    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])
  return (
    <div className=' bg-white  min-h-[80vh] flex justify-center items-center'>
      <div className="bg-white border border-orange-600 rounded-2xl m-3 p-4  w-[30em] h-max wimax ">
        <h2 className=' p-2 text-center  text-[25px] text-orange-500'>Ajouter une nouvelle Resource</h2>
        <form onSubmit={handleSubmit}>
          <div className=" transition-all flex flex-col mb-6  p-1">
            <div className="flex w-full justify-between responsivLogin my-3">
              <div class="relative h-11 w-full bloksInput mr-2 ">
                <input value={isTitre} onInput={(e) => SetisTitre(e.target.value)} className={`peer  h-full w-full border-b-2 border-orange-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTitre}`} placeholder=" " />
                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Titre du Resource
                </label>
              </div>
            </div>
            <div className="relative top-6">
              <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
              <label htmlFor="profils" className={` transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white ${animationClassProfil}`}>Selection le profile</label>
              <input type="file" id='profils' name='file' hidden accept=".mp4, .jpg, .jpeg, .png,.mov,.avi" onChange={handleFileChange} />
              <div className={`animeBorder p-1 relative transition-all  ${mobile ? 'left-1 border-2' : 'left-20 border-4'} top-4  rounded  border-dotted border-orange-500 relative overflow-hidden   ${file == null ? 'h-[0px] opacity-0 ' : 'h-[200px] w-[200px] opacity-100'}`}>

                {(() => {
                  if (!file) return null;
                  const extension = file.name.split('.').pop().toLowerCase();
                  const fileUrl = URL.createObjectURL(file);
                  if (extension === 'mp4') {
                    return (
                      <div className="file-container relative">
                        {loading && <div className="absolute w-full h-full  inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                        }
                        <ReactPlayer
                          onReady={() => {
                            setTimeout(() => {
                              SetLoading(false)
                            }, 1000)
                          }} url={fileUrl} controls className=" inline w-full h-full" />
                      </div>
                    )
                  } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                    return (<>

                      {loading && <div className="absolute w-full h-full  inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                      }
                      <img
                        onLoad={() => {
                          setTimeout(() => {
                            SetLoading(false)
                          }, 1000)
                        }} src={fileUrl} alt="Image" className=' w-full h-full rounded object-cover' />;
                    </>)
                  }
                })()}
              </div>
            </div>
          </div>
          <div className="relative h-full w-full mt-[5em]">
            <textarea value={isTextarea} onChange={(e) => { SetisTextarea(e.target.value); setautoScrollHeight(e.target.scrollHeight); setvalueTextarea(e.target.value.trim().length); if (!(valueTextarea)) { setautoScrollHeight(50) } }} placeholder=" " style={{ height: `${autoScrollHeight}px` }} className={`peer personalTextarea leading-none    w-full border-b-2 border-orange-700  py-2 px-1     font-normal resize-none  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  bg-transparent ${animationClassTextarea}`} />
            <label className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px]   peer-placeholder-shown:-top-[10px]   flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all  -bottom-1.5 blockw-full  duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500 `}>
              La déscription de Resource
            </label>
          </div>






          {spinnerButton ? (
            <>
              <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80">
                <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                  <FadeLoader
                    color="rgb(255, 255, 255)"
                    height={10}
                    margin={-9}
                    radius={100}
                    speedMultiplier={1}
                    width={1}
                  /></div>
                <input type="submit" id="send" value="Ajouter" class=" transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-end items-center mt-2">
                <label htmlFor='sende' className="w-max cursor-pointer  flex justify-end p-1 bg-orange-600 rounded  px-2 py-1 text-white">
                  <input type="submit" className='pointer-events-none' id='sende' value="Ajouter" />
                  <i title="Ajouter" class="bi bi-send ml-2 "></i>
                </label>
              </div>
            </>
          )}

        </form>
      </div>
    </div>
  )
}

export default AjoutRessourceAdmin

