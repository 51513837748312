import React from 'react'
import DetailAnnonces from './DetailAnnonces'
import NavBarsAnnonces from '../NavBarsAnnonces/NavBarsAnnonces'
function DetailAdminAnnoncesComponet() {
    return (
        <>
            <NavBarsAnnonces />
            <DetailAnnonces />
        </>
    )
}

export default DetailAdminAnnoncesComponet
