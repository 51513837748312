import React from 'react'
import NavBarsRessource from '../NavBarsRessour/NavBarsRessource'
import DetaillRessourceAdmin from './DetaillRessourceAdmin'
function DetaillRessourceAdminComponent() {
  return (
    <>
      <NavBarsRessource />
      <DetaillRessourceAdmin />
    </>
  )
}

export default DetaillRessourceAdminComponent
