/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
function Suggestion() {
  const [sugestions, setsugestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    axios
      .get(`https://irakoze.burundientempsreel.com/api/getallsuggestion?page=${currentPage}`)
      .then((response) => {
        const { suggestion, totalPages } = response.data;
        setsugestions(suggestion);
        setTotalPages(totalPages);
        setTimeout(() => {
          setPending(false)
        }, 100)
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [currentPage]);

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={currentPage === i ?
            "bg-orange-700 w-[30px] h-[30px] flex justify-center items-center rounded-full text-white" : "bg-gray-200 hover:bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full"}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  function transformDate(isoDate) {
    const date = new Date(isoDate);
    const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const day = frenchDays[date.getDay()];
    const dayNumber = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    hours = hours > 9 ? hours : '0' + hours;

    let time = `${hours}h${minutes}`
    return (<>
      <p className='mx-2'>
        {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}
      </p>
      <p className='ml-2'>
        à {time}
      </p>
    </>
    )
  }

  function handleDelete(id, position) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Etes-vous sûr que vous voulez supprimer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SUPPRIMER',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'swal-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://irakoze.burundientempsreel.com/api/deletesuggestion/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success('la sugestion a été supprimé avec succès');
              const AllSuggestions = [...sugestions]
              AllSuggestions.splice(position, 1)
              setsugestions(AllSuggestions)
            } else {
              toast.error("Une erreur s'est produite lors de la suppression du fichier");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Une erreur s'est produite lors de la suppression du fichier");
          });
      }
    });
  }
  return (
    <div className='w-full min-h-[75vh] p-2 flex flex-col items-start'>
      {pending && <Loading />}
      {sugestions && (
        sugestions.map((sugestion, position) => {
          return (
            <div className="border px-2 py-1 border-orange-500 rounded-2xl h-max m-4 w-[95%] p-[10px]">
              <div className="py-5">
                <p>Nom: {sugestion.nom}</p>
                <p>Prenom: {sugestion.prenom}</p>
                <p>Email: {sugestion.email}</p>
              </div>
              <div className="text-gray-700 mb-3">
                {sugestion.suggestion}
              </div>
              <div className="text-gray-400 my-2">{transformDate(sugestion.updatedAt)}</div>
              <div className=" flex justify-end">

                <button onClick={() => handleDelete(sugestion.id, position)} className='bg-red-500 text-white p-1 hover:bg-red-700 transition-all rounded'>Suppression</button>

              </div>
            </div>
          )
        })
      )}
      <div className=" w-full flex justify-center items-center">
        <div class=" w-[25em] h-full flex justify-around items-center p-2">
          <button onClick={previousPage} disabled={currentPage === 1} class=" transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded">
            <a href="#" class="mr-2">&laquo;</a>
            <p>Precedant</p>
          </button>
          <button class="pagination-link">
            {generatePageNumbers()}
          </button>
          <button onClick={nextPage} disabled={currentPage === totalPages} class=" transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded">
            <p>Suivant</p>
            <a href="#" class="ml-2">&raquo;</a>
          </button>
        </div>

      </div>
    </div>
  )
}

export default Suggestion
