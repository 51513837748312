import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

function NavBarsContact() {
    const { pathname } = useLocation()
    let AdminContact = /^\/Admin\/Contact.*/
    let AdminContactReseauxSoci = /^\/Admin\/SocialContact.*/
    let AdminContactSuggestion = /^\/Admin\/SuggestionContact.*/

    const [mobile, SetMobile] = useState(window.innerWidth < 543);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 543)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    return (
        <div className='bg-gray-200 w-full pt-2 flex justify-around sticky top-0 z-10'>
            {AdminContact.test(pathname) ? (
                <Link to='/Admin/Contact' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-geo-alt ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                    <p className={`sizeText ${mobile ? 'hidden' : 'block'}`}>Coordonner physique</p>
                </Link>
            ) : (
                <Link to='/Admin/Contact' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-geo-alt ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                    <p className={`sizeText ${mobile ? 'hidden' : 'block'}`}>Coordonner physique</p>
                </Link>
            )}
            {AdminContactReseauxSoci.test(pathname) ? (
                <Link to='/Admin/SocialContact' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={` bi bi-badge-4k  ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                    <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Réseaux sociaux</p>
                </Link>
            ) : (
                <Link to='/Admin/SocialContact' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={` bi bi-badge-4k  ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                    <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Réseaux sociaux</p>
                </Link>
            )}
            {AdminContactSuggestion.test(pathname) ? (
                <Link to='/Admin/SuggestionContact' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-emoji-frown ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'}`}></i>
                    <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Suggestion</p>
                </Link>
            ) : (
                <Link to='/Admin/SuggestionContact' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-emoji-frown ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'}`}></i>
                    <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Suggestion</p>
                </Link>
            )}
        </div>
    )
}

export default NavBarsContact
