import React from 'react'
import EditorRessources from './EditorRessources'
import NavBarsRessource from '../NavBarsRessour/NavBarsRessource'
function EditorRessourcesComponent() {
    return (
        <>
            <NavBarsRessource />
            <EditorRessources />
        </>
    )
}

export default EditorRessourcesComponent