import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../../Loading/Loading';
function Autorite() {
    const [administration, setAdministration] = useState([]);
    const [isPending, setPending] = useState(true)

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/administration').then((response) => {
            setAdministration(response.data);
            setTimeout(() => {
                setPending(false)
            }, 100)
        }).catch((err) => {
            console.log(err.message)
        })
    }, []);

    return (
        <>
            <div className="w-full  bg-white  flex flex-col mt-[20px] mx-4  justify-center items-center border-2 border-[#00000017] ">
                {isPending && <Loading />}

                {administration.length > 0 ? (
                    <>
                        <h2 className='text-[20px]  px-4 p-1 my-2 rounded text-orange-400 '>Les hautes autorités du Lycée d'Excellence NGAGARA</h2>
                        <div className="flex flex-wrap justify-center">
                            {
                                administration.map((value) => {
                                    return (
                                        <div
                                            className="members border rounded flex overflow-hidden m-4 justify-center flex-col items-center w-max h-max p-2"
                                            key={value.id}
                                        >
                                            <div className="w-[10em] h-[10em] members1 animeTop rounded-full overflow-hidden">
                                                <img
                                                    src={`https://irakoze.burundientempsreel.com/uploads/membres/${value.file}`}
                                                    alt=""
                                                    className='w-full h-full object-cover'
                                                />
                                            </div>
                                            <div className="w-full mt-3">
                                                <p>Nom : {value.nom} </p>
                                                <p>Prenom: {value.prenom}</p>
                                                <p>Fonction: {value.fonction}</p>
                                                <p>Tel: {value.tel}</p>
                                                <p>Email: {value.email}</p>
                                            </div>
                                        </div>
                                    );

                                })}
                        </div>
                    </>
                ) : (<div></div>)}







            </div >
        </>
    )
}

export default Autorite
