import React from 'react'
import EvenementNavBars from '../EvenementNavNars/EvenementNavBars'
import AddMoreAdminEvent from './AddMoreAdminEvent'
function AddMoreAdminEventComponent() {
    return (
        <>
            <EvenementNavBars />
            <AddMoreAdminEvent />
        </>
    )
}

export default AddMoreAdminEventComponent
