/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../../../Loading/Loading';
function DetailTemoignage() {
    const history = useNavigate();
    const { id } = useParams();
    const [temoignage, setTemoignage] = useState({});
    const [isPending, setPending] = useState(true)
    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getontemoignage/${id}`)
            .then((response) => {
                setTemoignage(response.data);
                setTimeout(() => {
                    setPending(false);
                }, 100);
            })
            .catch((error) => {
                console.error('Une erreur s\'est produite lors de la récupération du témoignage:', error);
                setPending(false);
            });
    }, []);
    function transformDate(isoDate) {
        const date = new Date(isoDate);
        const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const day = frenchDays[date.getDay()];
        const dayNumber = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        hours = hours > 9 ? hours : '0' + hours;

        let time = `${hours}h${minutes}`
        return (<>
            <p className='mx-2'>
                {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}
            </p>
            <p className='ml-2'>
                à {time}
            </p>
        </>
        )
    }

    function handleDelete(idd) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Etes-vous sûr que vous voulez supprime?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/deletetemoignage/${idd}`)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('le temoignage a été supprimé avec succès');
                            history('/Admin/Temoignage');
                        } else {
                            toast.error("Une erreur s'est produite lors de la supplession du temoignage");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message)
                        //toast.error("Une erreur s'est produite lors de la supplession du temoignage");
                    });
            }
        });
    }



    return (
        <div className="flex justify-center min-h-[75vh]  items-center w-full">
            {isPending && <Loading />}
            <div className="bg-gray-100 w-[95%] p-2 my-5  rounded flex flex-col justify-center  items-center">
                <div className="flex flex-col w-[90%]">
                    <div className="trasition-all w-full hover:text-orange-500 mt-3">
                        <button className='text-center font-serif p-1  w-full h-full text-[18px] '>{temoignage.titre}</button>
                    </div>
                    <p className='text-gray-500 my-3 '>

                        {temoignage.contenue}
                        <div className='text-gray-400'>{temoignage.nom} {temoignage.prenom} ,{transformDate(temoignage.createdAt)}</div>
                    </p>
                </div>
                <div className=" flex justify-around p-1 mt-5 font-serif w-full">
                    <button onClick={() => handleDelete(temoignage.id)} className='transition-all bg-red-400 hover:bg-red-600 text-white p-1 rounded'>Supprimer</button>
                    <Link to='/Admin/Temoignage' className='transition-all bg-green-400 hover:bg-green-600 text-white p-1 rounded'>Retourn</Link>
                </div>
            </div>
        </div>
    )
}

export default DetailTemoignage
