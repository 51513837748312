import React from 'react'
import { Link } from 'react-router-dom'

function Profile() {
  return (
    <div className='w-full h-full flex justify-center items-center'>
      <div className=" bg-orange-200 rounded flex flex-col items-center border p-2 w-[25em] h-max profil">
        <div className="bg-white temoign  p-2 rounded  m-1 w-[7em] h-[7em]" >
          <img src="https://irakoze.burundientempsreel.com/uploads/Photo/btr.png" alt="" className=' w-full h-full' />
        </div>
        <div className="w-full">
          <div className='text-[18px] text-gray-500'>
            <p>Nom: Masenge Mamam</p>
            <p>Prenom: Masenge</p>
            <p>Tel: +209 790 322 829</p>
            <p>Email: telecomtelesphore@gmail.com</p>
            <p>Votre genre:Masculin</p>
          </div>
        </div>
        <div className="w-full flex justify-end ">
          <Link to='/AdminAccueil' className='bg-green-400 mr-6 text-white p-1 rounded hover:bg-green-600 transition-all'>Retour</Link>
          <Link to="/Admin/Profil/Modifier" className='bg-orange-400 text-white p-1 rounded hover:bg-orange-600 transition-all'>Modifier</Link>
        </div>
      </div>
    </div>
  )
}

export default Profile


