import React from 'react'
import EditorAnnonces from './EditorAnnonces'
import NavBarsAnnonces from '../NavBarsAnnonces/NavBarsAnnonces'
function EditorAnnonceComponent() {
    return (
        <>
            <NavBarsAnnonces />
            <EditorAnnonces />
        </>

    )
}

export default EditorAnnonceComponent