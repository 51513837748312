import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function NavBarsAnnonces() {
    const { pathname } = useLocation()
    let AdminAnnonces = /^\/Admin\/Annonce.*/
    let AdminAnnoncesAjouter = /^\/Admin\/AjouterAnnonce.*/
    return (
        <div className='bg-gray-200 w-full pt-2 flex justify-around sticky top-0 z-10'>
            {AdminAnnonces.test(pathname) ? (
                <Link to='/Admin/Annonce' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                    <p className='sizeText'>Gérer annonce</p>
                </Link>
            ) : (
                <Link to='/Admin/Annonce' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                    <p className='sizeText'>Gérer annonce</p>
                </Link>
            )}
            {AdminAnnoncesAjouter.test(pathname) ? (
                <Link to='/Admin/AjouterAnnonce' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                    <p className='sizeText'>Ajouter annonce</p>
                </Link>
            ) : (
                <Link to='/Admin/AjouterAnnonce' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                    <p className='sizeText'>Ajouter annonce</p>
                </Link>
            )}
        </div>
    )
}

export default NavBarsAnnonces

