import React from 'react'
import EvenementNavBars from '../EvenementNavNars/EvenementNavBars'
import EditeEvenement from './EditeEvenement'
function EditeEvenementComponent() {
  return (
    <>
      <EvenementNavBars />
      <EditeEvenement />
    </>
  )
}

export default EditeEvenementComponent