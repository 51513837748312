/* eslint-disable eqeqeq */
import axios from 'axios';
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners';
import Loading from '../component/Loading/Loading';
function Login({ SetisAdmin, isAdmin }) {

    const [spinnerButton, SetSpinnerButton] = useState(false)
    const [spinnerPage, SetspinnerPage] = useState(true)
    const [mobile, setMobille] = useState(window.innerWidth < 569)

    const history = useNavigate();
    const [isEmail, SetisEmail] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const emailRef = useRef(null)

    const [isPass, SetisPass] = useState('')
    const [animationClassisPass, setAnimationClassPass] = useState('');
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const passRef = useRef(null)
    const [passwordVisible, setPasswordVisible] = useState(false);



    const handleSubmit = (e) => {
        e.preventDefault()
        if (isEmail.trim() == '') {
            toast.warning("L'address email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            emailRef.current.focus()
            return;
        }
        else if (!isEmail.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            emailRef.current.focus()
            return;
        }
        else if (isPass.trim() == '') {
            toast.warning('Le  password est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPass(' ')
            }, 3000)
            passRef.current.focus()
            return;
        } else if (!isPass.trim().match(PasswordRegex)) {
            toast.error(`Le mot de passe doit contenir au moins une lettre minuscule,une lettre majuscule, un chiffre et uncaractère spécial`, {
                autoClose: 10000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPass(' ')
            }, 3000)
            passRef.current.focus()
            return;
        }
        SetSpinnerButton(true);
        axios.post('https://irakoze.burundientempsreel.com/api/user/login', {
            'email': isEmail,
            'password': isPass

        }).then((response) => {
            if (response.data.error) {
                toast.warning(response.data.error, {
                    autoClose: 7000
                });

            } else {
                localStorage.setItem('v', response.data.userId)
                localStorage.setItem('vvvvv', response.data.token)
                SetisAdmin(true);
                history('/AdminAccueil');

            }
        }).catch((error) => {

            console.log("Error message:", error.message);
            if (error.response) {
                toast.warning(error.response.data.error, {
                    autoClose: 2000
                });
            }
        }).finally(() => {
            SetSpinnerButton(false);
        });;

    }
    useEffect(() => {
        const hundleSize = () => {
            setMobille(window.innerWidth < 569)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })

    return (
        <div className='w-full h-[100vh] bg-white flex justify-center items-center'>

            <div className={`flex w-full ${mobile ? 'flex-col' : ''}  h-full justify-center items-center`}>
                <div className={`bg-[blue]  ${mobile ? 'w-[90%] h-max' : 'w-[40%] h-[50%]'}  overflow-hidden relative border-double-[10px] border-gray-500 shadow1  rounded p-2 flex flex-col items-center`}>
                    <img src="image/logso.png" alt="" className='animeTop w-full h-full object-contain z-10' />
                    <div className="boule">
                        <span className="namie1 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute top-2 left-4"></span>
                        <span className="namie2 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute bottom-20"></span>
                        <span className="namie4 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute bottom-10 right-36"></span>
                        <span className="namie4 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute top-10 rigth-3"></span>
                        <span className="namie1 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute right-9 bottom-10"></span>
                        <span className="namie5 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute right-20 bottom-3"></span>
                        <span className="namie4 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute top-20 left-2"></span>
                        <span className="namie5 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute bottom-20"></span>
                        <span className="namie1 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute bottom-5 left-2"></span>
                        <span className="namie2 bg-orange-500 w-[30px] h-[30px] rounded-full m-1 absolute top-3 left-36"></span>
                    </div>
                </div>
                <div className={`bg-white  ${mobile ? 'w-[90%] h-max' : 'w-[40%] h-[50%]'} shadow1  rounded p-2 flex flex-col items-center`}>
                    <h2 className='py-2 text-gray-600 text-[20px] mb-[10px] text-center'>Nous sommes ravis de vous accueillir parmi nous.</h2>
                    <form onSubmit={handleSubmit} className='w-full  h-full' >
                        <div className="flex w-full justify-between flex-col">
                            <div className="flex items-center">
                                <i className='bi bi-envelope-fill mr-1 mt-4'></i>
                                <div class="relative h-11 w-full bloksInput mr-2 ">
                                    <input ref={emailRef} value={isEmail} onInput={(e) => SetisEmail(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" " />
                                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Email
                                    </label>
                                </div>
                            </div>
                            <div className=" transition-all mt-10 flex w-[100%] justify-between conctat">
                                <div className="flex items-center w-full ">
                                    <i className='bi bi-key-fill mr-1'></i>
                                    <div className={`flex items-center w-full -400 relative`}>
                                        <div class="relative h-11 w-full mb-6 bloksInput mr-2 ">
                                            <input ref={passRef} onInput={(e) => { SetisPass(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassisPass}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Mot de passe
                                            </label>
                                        </div>
                                        <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] pb-2 absolute flex justify-center items-center right-2 ">
                                            <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-gray-700'>Vous n'avez pas un compte? <Link to='/regis' className='text-blue-600'>Créer un compte</Link> </div>

                        </div>
                        <div className="flex relative justify-end items-center">
                            {spinnerButton ? (
                                <>
                                    <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80">
                                        <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                                            <FadeLoader
                                                color="rgb(255, 255, 255)"
                                                height={10}
                                                margin={-9}
                                                radius={100}
                                                speedMultiplier={1}
                                                width={1}
                                            /></div>
                                        <input type="submit" id="send" value="Se connecter" class=" transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex justify-end items-center mt-4">
                                        <input type="submit" id="send" value="Se connecter" class=" transition-all bg-orange-400 rounded hover:bg-orange-600 cursor-pointer px-5 py-1 text-white"></input>
                                    </div>

                                </>
                            )}

                        </div>
                    </form>
                </div>
            </div>
        </div>)
}

export default Login
