import React from 'react'
import NavBarsRessource from '../NavBarsRessour/NavBarsRessource'
import GrerRessourcesAdmin from './GrerRessourcesAdmin'
function GrerRessourcesAdminComponent() {
    return (
        <>
            <NavBarsRessource />
            <GrerRessourcesAdmin />
        </>
    )
}

export default GrerRessourcesAdminComponent
