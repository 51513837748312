import React from 'react'
import DescriptionEcole from './DescriptionEcole'
import Membres from './Membres'
import Autorite from './Autorite'
import Historique from './Historique'
import Objectif from './Objectif'
import Partenariat from './Partenariat'
import Sloger from '../Home/Sloger'
function AproposComponent() {
    return (
        <>
            <Sloger />
            <DescriptionEcole />
            <Historique />
            <Objectif />
            <Partenariat />
            <Membres />
            <Autorite />
        </>
    )
}

export default AproposComponent
