import React from 'react'
import NavBarsContact from '../NavBarsContact/NavBarsContact'
import Suggestion from './Suggestion'
function SuggestionComponent() {
  return (
    <>
      <NavBarsContact />
      <Suggestion />

    </>
  )
}

export default SuggestionComponent
