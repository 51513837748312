/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../Loading/Loading';
import { toast } from 'react-toastify';
import { chiffrement, formatDate } from '../../../module';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';
function Resource() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isPending, setPending] = useState(true)
    const [eventSearch, setEventSearch] = useState([]);

    useEffect(() => {
        const iduser = localStorage.getItem('userid');

        axios
            .get(`https://irakoze.burundientempsreel.com/api/getallresources?page=${currentPage}`)
            .then((response) => {
                const { sources, totalPages } = response.data;
                setData(sources);
                setTotalPages(totalPages);
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [currentPage])
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Mois (ajout de zéros à gauche si nécessaire)
        const day = String(today.getDate()).padStart(2, '0'); // Jour (ajout de zéros à gauche si nécessaire)

        const formattedDate = `${year}-${month}-${day}`;
        setCurrentDate(formattedDate);
    }, []);

    const handleDateChange = (e) => {
        setCurrentDate(e.target.value);
        setSearching(true); // Indiquer que la recherche est en cours
        axios.get('https://irakoze.burundientempsreel.com/api/source/bydate', { params: { date: e.target.value } })
            .then((response) => {
                if (response.data === 'événement introuvable !') {
                    toast.error("aucun ressource pour la date mantionnée", {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setEventSearch([]);
                } else {
                    setEventSearch(response.data);
                }
                setSearching(false);
            })
            .catch((err) => {
                console.error('Event not found!');
                setSearching(false);
            });
    };
    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobille2] = useState(window.innerWidth < 667);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille2(window.innerWidth < 667)
            setMobille3(window.innerWidth < 443)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })



    const [titre, setTitre] = useState('');
    const [searching, setSearching] = useState(false);
    const search = (titre) => {
        if (titre) {
            setSearching(true); // Indiquer que la recherche est en cours
            axios.get('https://irakoze.burundientempsreel.com/api/source/bytitre', { params: { titre: titre } })
                .then((response) => {
                    if (response.data === 'événement introuvable !') {
                        toast.error("Ressource introuvable !", {
                            position: "top-center",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTitre("")
                        eventSearch.length = 0
                    } else {
                        setEventSearch(response.data);
                    }
                    setSearching(false);
                })
                .catch((err) => {
                    console.error('Event not found!');
                    setSearching(false);
                });
        } else {
            eventSearch.length = 0
        }
    }


    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    return (
        <>
            {isPending && <Loading />}
            <div className="w-full flex  mt-1 justify-center items-center ">
                <div className='p-2 w-full min-h-[20em]'>
                    <div className="  w-full   rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                        {data.length > 0 && (
                            <>
                                <h2 className='text-[35px] font-serif text-gray-700 px-4 p-1 my-2 rounded '>Ressource</h2>
                                <div className='w-full  p-1  flex'>
                                    <input placeholder='Resource..' value={titre}
                                        onChange={(e) => { setTitre(e.target.value); search(e.target.value) }}
                                        className='px-2 w-[10em] h-8  border-orange-500 border rounded-l-2xl outline-none' />
                                    <input type='date' value={currentDate}
                                        onChange={handleDateChange} className='px-2 h-8 border-orange-500 border rounded-r-2xl outline-none' />
                                </div></>
                        )}




                        <div className='h-[1px] mb-1  bg-gray-300 w-full'></div>

                        {eventSearch.length > 0 ? (

                            <div className={`flex flex-wrap  justify-center  gap-2 p-4`}>
                                {eventSearch.map((event, index) => {
                                    return (

                                        <div className={` px-[20px] ${mobile2 ? 'flex-auto h-max' : ''}  border border-orange-700 rounded-2xl`}>
                                            <h2 className='font-semibold  w-full'>{event.titre}</h2>
                                            <span className='font-sans text-[13px] text-orange-700'>Le {formatDate(event.createdAt)}</span>
                                            <div className={` m-[2px] ${mobile2 ? 'w-full h-full ' : mobile1 ? 'h-[15em] w-[15em]' : 'h-[20em] w-[20em]'}    rounded overflow-hidden`}>
                                                {event.file && (() => {
                                                    const extension = event.file.split('.').pop();
                                                    if (extension === 'pdf') {

                                                        return (
                                                            <div>PDF introuvable</div>
                                                        );
                                                    } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                        return (

                                                            <>
                                                                <div key={event.id} className='relative border h-full w-full '>
                                                                    {loadingImages[index] ? (
                                                                        <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                    ) :
                                                                        (
                                                                            <ReactPlayer
                                                                                url={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                                                controls
                                                                                className=" inline object-cover object-center w-full h-full"

                                                                            />
                                                                        )}

                                                                </div>
                                                            </>


                                                        );
                                                    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                        return (
                                                            <div key={event.id} className='relative  h-full w-full '>
                                                                {loadingImages[index] ? (
                                                                    <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                ) :
                                                                    (
                                                                        <LoadingImage
                                                                            src={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                                            className="w-full h-full border-none object-contain"
                                                                            onLoad={() => handleImageLoad(index)}
                                                                        />
                                                                    )}

                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })()}
                                            </div>
                                            <div className="w-full mt-1 p-3">
                                                <div className={`min-h-[5em] ${mobile2 ? 'w-full' : 'w-[14em]'}`}>
                                                    <div className='w-full  text-center'>
                                                        {event.description && event.description.length > 100
                                                            ? event.description.slice(0, 100) + '...'
                                                            : event.description}
                                                    </div>
                                                </div>
                                                <div className=" h-[2.3em] w-full flex justify-center"><Link to={`/Visit/Ressource/Detail/${chiffrement(event.id)}`} className='bg-orange-500 transition-all hover:bg-orange-600 text-white px-4 p-1 mt-2 rounded'>En savoir plus</Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : data.length > 0 ? (
                            <>
                                <div className={`flex flex-wrap  justify-center  gap-2 p-4`}>
                                    {data.map((event, index) => {
                                        return (

                                            <div className={` px-[20px] ${mobile2 ? 'flex-auto h-max' : ''}  border border-orange-700 rounded-2xl`}>
                                                <h2 className='font-semibold  w-full'>{event.titre}</h2>
                                                <span className='font-sans text-[13px] text-orange-700'>Le {formatDate(event.createdAt)}</span>
                                                <div className={` m-[2px] ${mobile2 ? 'w-full h-full ' : mobile1 ? 'h-[15em] w-[15em]' : 'h-[20em] w-[20em]'}    rounded overflow-hidden`}>
                                                    {event.file && (() => {
                                                        const extension = event.file.split('.').pop();
                                                        if (extension === 'pdf') {

                                                            return (
                                                                <div>PDF introuvable</div>
                                                            );
                                                        } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                            return (

                                                                <>
                                                                    <div key={event.id} className='relative border h-full w-full '>
                                                                        {loadingImages[index] ? (
                                                                            <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                        ) :
                                                                            (
                                                                                <ReactPlayer
                                                                                    url={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                                                    controls
                                                                                    className=" inline object-cover object-center w-full h-full"

                                                                                />
                                                                            )}

                                                                    </div>
                                                                </>


                                                            );
                                                        } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                            return (
                                                                <div key={event.id} className='relative  h-full w-full '>
                                                                    {loadingImages[index] ? (
                                                                        <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                    ) :
                                                                        (
                                                                            <LoadingImage
                                                                                src={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                                                className="w-full h-full border-none object-contain"
                                                                                onLoad={() => handleImageLoad(index)}
                                                                            />
                                                                        )}

                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })()}
                                                </div>
                                                <div className="w-full mt-1 p-3">

                                                    <div className={`min-h-[5em] ${mobile2 ? 'w-full' : 'w-[14em]'}`}>
                                                        <div className='w-full  text-center'>
                                                            {event.description && event.description.length > 100
                                                                ? event.description.slice(0, 100) + '...'
                                                                : event.description}
                                                        </div>
                                                    </div>
                                                    <div className=" h-[2.3em] w-full flex justify-center"><Link to={`/Visit/Ressource/Detail/${chiffrement(event.id)}`} className='bg-orange-500 transition-all hover:bg-orange-600 text-white px-4 p-1 mt-2 rounded'>En savoir plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className='w-full'>
                                    <button onClick={nextPage}
                                        disabled={currentPage === totalPages} className='bg-orange-600 flex items-center w-max px-4 mb-4 hover:border transition-all border border-gray-50 hover:border-orange-500 cursor-pointer hover:bg-transparent hover:text-gray-800  text-white rounded-2xl py-1'>
                                        Voir autres
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square-fill ml-2" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                    <button onClick={previousPage}
                                        disabled={currentPage === 1} className='bg-orange-600 flex items-center w-max px-4 mb-4 hover:border transition-all border border-gray-50 hover:border-orange-500 cursor-pointer hover:bg-transparent hover:text-gray-800  text-white rounded-2xl py-1'>
                                        Voir enciens
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM14.346 8.5 8 1.731 1.654 8.5H4.5a1 1 0 0 1 1 1v1h5v-1a1 1 0 0 1 1-1h2.846zm-9.846 5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1zm6 0h-5v1h5v-1z" />
                                        </svg>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className={` flex w-[50em]  flex-col items-center  border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss`}>
                                <EmpyServices items="de ressources" />
                                <div className='text-[18px] font-sans text-gray-700'>
                                    Les ressources dans les écoles secondaires revêtent une importance significative pour l'éducation et le développement des élèves. Elles contribuent à enrichir l'environnement d'apprentissage et à soutenir leur réussite académique et personnelle.
                                    <br /><br />
                                    Les infrastructures physiques, y compris les salles de classe, les laboratoires, les installations sportives et les bibliothèques, sont des ressources essentielles. Elles offrent un cadre propice à l'apprentissage et à l'exploration. Les laboratoires scientifiques, par exemple, permettent aux élèves de mener des expériences pratiques qui renforcent leur compréhension des sciences.
                                </div>
                            </div>
                        )
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

export default Resource
