/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function NavBarsSlide() {
    const { pathname } = useLocation()
    let AdminAceuil = /^\/AdminAccueil.*/
    let AdminEvent = /^\/Admin\/Evenement.*/
    let AdminEventAjout = /^\/Admin\/AjoutEvenement.*/

    let AdminTemoignage = /^\/Admin\/Temoignage.*/
    let AdminTemoignageStandBuy = /^\/Admin\/StandyBuy.*/

    let AdminAnnonces = /^\/Admin\/Annonce.*/
    let AdminAnnoncesAjouter = /^\/Admin\/AjouterAnnonce.*/



    let AdminRessources = /^\/Admin\/Ressources.*/
    let AdminRessourcesAjouter = /^\/Admin\/AjouteRessources.*/

    let AdminMembre = /^\/Admin\/Members.*/
    let AdminMembreAjouter = /^\/Admin\/AjouterMembers.*/

    let AdminContact = /^\/Admin\/Contact.*/
    let AdminContactReseauxSoci = /^\/Admin\/SocialContact.*/
    let AdminContactSuggestion = /^\/Admin\/SuggestionContact.*/



    const [mobile, SetMobile] = useState(window.innerWidth < 776);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 776)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <div className={`w-[14em] h-full bg-gray-300 border-r-2 border-orange-300  flex-col items-center ${mobile ? 'hidden' : 'flex'} `}>

            <div className="w-[9em] h-[9em] mt-1 rounded overflow-hidden ">
                <img src="https://irakoze.burundientempsreel.com/uploads/Photo/LENGA2.png" alt="" className='block w-full h-full object-cover object-center' />
            </div>

            <div className="w-full h-[75%] overflow-x-hidden overflow-y-auto border-t-2 border-orange-500">
                <div className="w-full h-100% font-serif">


                    {AdminAceuil.test(pathname) || pathname == '/' ? (
                        <Link to='/AdminAccueil' className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Acceuil</p>
                        </Link>
                    ) : (
                        <Link to='/AdminAccueil' className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Acceuil</p>
                        </Link>
                    )}

                    {AdminEvent.test(pathname) || AdminEventAjout.test(pathname) ? (
                        <Link to='/Admin/Evenement' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                            <p className='sizeText'>Evénement</p>
                        </Link>

                    ) : (
                        <Link to='/Admin/Evenement' className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                            <p className='sizeText'>Evénement</p>
                        </Link>
                    )}

                    {AdminTemoignage.test(pathname) || AdminTemoignageStandBuy.test(pathname) ? (
                        <Link to="/Admin/Temoignage" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600  transition-all text-fuchsia-50  hover:text-fuchsia-50 '>
                            <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                            <p className='sizeText'>Témoignage</p>
                        </Link>
                    ) : (
                        <Link to="/Admin/Temoignage" className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                            <p className='sizeText'>Témoignage</p>
                        </Link>
                    )}


                    {AdminAnnonces.test(pathname) || AdminAnnoncesAjouter.test(pathname) ? (
                        <Link to="/Admin/Annonce" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Annonce</p>
                        </Link>
                    ) : (
                        <Link to="/Admin/Annonce" className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Annonce</p>
                        </Link>
                    )}


                    {AdminRessources.test(pathname) || AdminRessourcesAjouter.test(pathname) ? (
                        <Link to="/Admin/Ressources" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                            <p className='sizeText'>Ressource</p>
                        </Link>
                    ) : (
                        <Link to="/Admin/Ressources" className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                            <p className='sizeText'>Ressource</p>
                        </Link>
                    )}
                    {AdminMembre.test(pathname) || AdminMembreAjouter.test(pathname) ? (
                        <Link to="/Admin/Members" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-people-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Membre</p>
                        </Link>
                    ) : (
                        <Link to="/Admin/Members" className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-people-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Membre</p>
                        </Link>
                    )}
                    {AdminContact.test(pathname) || AdminContactReseauxSoci.test(pathname) || AdminContactSuggestion.test(pathname) ? (
                        <Link to="/Admin/Contact" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Contact</p>
                        </Link>
                    ) : (
                        <Link to="/Admin/Contact" className='flex text-gray-800  justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Contact</p>
                        </Link>
                    )}
                </div>
            </div>

        </div>
    )
}

export default NavBarsSlide
