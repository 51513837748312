/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Loading from '../../Loading/Loading';
import PropTypes from 'prop-types'

function AcceuilAdmin() {


    useEffect(() => {
        const timeout = setTimeout(() => {

            console.log('La page a été actualisée après une seconde');
        }, 1000);

        return () => clearTimeout(timeout); // Annuler le timeout si le composant est démonté avant la fin de la seconde
    }, []);
    const [evenement, setEvenement] = useState('');
    const [age, setage] = useState('');
    const [annonce, setannonce] = useState('');
    const [resource, setresource] = useState('');
    const [encadreur, setencadreur] = useState('');
    const [countenseignant, setcountenseignant] = useState('');
    const [countebliotequaires, setcountebliotequaires] = useState('');
    const [countesecretaires, setcountesecretaires] = useState('');
    const [pending, setPending] = useState(true);

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/countevenement').then((response) => {
            setEvenement(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countage').then((response) => {
            setage(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countannonces').then((response) => {
            setannonce(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countresource').then((response) => {
            setresource(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countencadreur').then((response) => {
            setencadreur(response.data)
            setTimeout(() => {
                setPending(false)
            }, 100)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countebliotequaires').then((response) => {
            setcountebliotequaires(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countenseignant').then((response) => {
            setcountenseignant(response.data)
            setTimeout(() => {
                setPending(false)
            }, 100)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/countesecretaires').then((response) => {
            setcountesecretaires(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        })
    }, [])





    const [admin, setAdministration] = useState([]);

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/administration').then((response) => {
            setAdministration(response.data);
            setTimeout(() => {
                setPending(false)
            }, 1000)
        }).catch((error) => {
            console.log(error.message);
        });
    }, []);



    const [mobile2, SetMobile2] = useState(window.innerWidth < 450.99383838992234)
    const [mobile3, SetMobile3] = useState(window.innerWidth < 1092.99383838992234)


    useEffect(() => {
        const hundleSize = () => {
            SetMobile2(window.innerWidth < 450.99383838992234)
            SetMobile3(window.innerWidth < 1092.99383838992234)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    const [loading, SetLoading] = useState(true)

    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };

    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    return (
        <div className='w-full flex flex-col items-center min-h-[80vh]'>
            {pending && <Loading />}
            <h1 className={` font-serif ${mobile2 ? 'text-[20px]' : 'text-[50px]'}`}><span className='text-yellow-600 animeBorder'>Bien</span><span className='text-orange-600'>venue</span></h1>

            <div className="w-full  h-max flex flex-col j ">
                <div className={`${mobile2 ? 'overflow-x-auto w-[95%]' : 'overflow-hidden w-full'} h-max flex flex-col  `}>
                    <table className={`${mobile2 ? 'w-[30em]' : 'w-[95%] '}  rounded-2xl border`}>
                        <thead className='bg-gray-100 h-11 rounded-2xl'>
                            <tr className='font-sans'>
                                <th className='text-left pl-3'>Evénements (nombres)</th>
                                <th className='text-left pl-3'>Témoignages (nombres)</th>
                                <th className='text-left pl-3'>Annonces (nombres)</th>
                                <th className='text-left pl-3'>Ressources (nombres)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='h-9'>
                                <td className='pl-3 border-l'>{evenement}</td>
                                <td className='pl-3 border-l'>{age}</td>
                                <td className='pl-3 border-l'>{annonce}</td>
                                <td className='pl-3 border-l'>{resource}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="w-full h-max  flex flex-wrap flex-col mt-10 items-cente">
                    <h1 className={` font-serif ${mobile2 ? 'text-[20px]' : 'text-[50px] '} text-center`}>Les membres</h1>
                    <div className="w-full h-max flex flex-col   ">
                        <div className={`${mobile2 ? 'overflow-x-auto w-[95%]' : 'overflow-hidden w-full'} h-max flex flex-col  `}>
                            <table className={`${mobile2 ? 'w-[30em]' : 'w-[95%] '}  rounded-2xl border`}>
                                <thead className='bg-gray-100 h-11 rounded-2xl'>
                                    <tr className='font-sans'>
                                        <th className='text-left pl-3'>Enseignants (nombres)</th>
                                        <th className='text-left pl-3'>Encadreurs (nombres)</th>
                                        <th className='text-left pl-3'>Bibliotequaires (nombres)</th>
                                        <th className='text-left pl-3'>Secretaires (nombres)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='h-9'>
                                        <td className='pl-3 border-l'>{countenseignant}</td>
                                        <td className='pl-3 border-l'>{encadreur}</td>
                                        <td className='pl-3 border-l'>{countebliotequaires}</td>
                                        <td className='pl-3 border-l'>{countesecretaires}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {!admin.length <= 0 ? (
                    <div className="w-full h-max  flex flex-col    mt-10 justify-center">
                        <h1 className='w-full text-center font-serif text-[35px] text-orange-500 animeBorder'>Administration</h1>
                        <div className="flex flex-wrap justify-around">
                            <div className={`${mobile3 ? 'overflow-x-auto w-[99%]' : 'overflow-hidden w-full'} h-max flex flex-col  `}>
                                <table className={`${mobile3 ? 'w-[70em]' : 'w-[95%] '}  rounded-2xl border`}>
                                    <thead className='bg-gray-100 h-11 rounded-2xl'>
                                        <tr className='font-sans'>
                                            <th className='text-left pl-3'>Profil</th>
                                            <th className='text-left pl-3'>Nom</th>
                                            <th className='text-left pl-3'>Prénom</th>
                                            <th className='text-left pl-3'>Fonction</th>
                                            <th className='text-left pl-3'>Tél</th>
                                            <th className='text-left pl-3'>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admin.length > 0 && admin.map((value, position) => (
                                            <tr className='h-16 pl-4 py-2 border-b border-gray-500'>
                                                <td>
                                                    <div key={position} class="flex-shrink-0 w-10 h-10 overflow-hidden relative">
                                                        {loadingImages[position] ? (
                                                            // eslint-disable-next-line jsx-a11y/alt-text
                                                            <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                        ) :
                                                            (
                                                                <LoadingImage
                                                                    src={`https://irakoze.burundientempsreel.com/uploads/membres/${value.file}`} className="w-full h-full border-none object-contain"
                                                                    onLoad={() => handleImageLoad(position)}
                                                                />
                                                            )}

                                                    </div>
                                                </td>

                                                <td className='pl-3 border-l'>{value.nom}</td> <td className='pl-3 border-l'>value.prenom</td><td className='pl-3 border-l'>{value.fonction}</td><td className='pl-3 border-l'>{value.tel}</td> <td className='pl-3 border-l'>{value.email}</td></tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>) : null}


            </div>
        </div>

    )
}

export default AcceuilAdmin
