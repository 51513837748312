import React from 'react'
import ModifierHoraires from './ModifierHoraires'
import NavBarsContact from '../NavBarsContact/NavBarsContact'
function ModifierHorairesComponent() {
    return (
        <>
            <NavBarsContact />
            <ModifierHoraires />
        </>
    )
}

export default ModifierHorairesComponent
