import React from 'react'
import NavBarsRessource from '../NavBarsRessour/NavBarsRessource'
import AjoutRessourceAdmin from './AjoutRessourceAdmin'
function AjoutRessourceAdminComponent() {
    return (
        <>
            <NavBarsRessource />
            <AjoutRessourceAdmin />
        </>
    )
}

export default AjoutRessourceAdminComponent
