import React from 'react'
import TemoignajeGeraAdmin from './TemoignajeGeraAdmin'
import NavBarsTemoignage from '../TemoignanegNavBars/NavBarsTemoignage'
function TemoignageAdminComponent() {
    return (
        <>
          <NavBarsTemoignage />
            <TemoignajeGeraAdmin />
        </>
    )
}

export default TemoignageAdminComponent
