import React from 'react'

function DescriptionEcole() {
    return (
        <div className='w-full h-max flex justify-center items-center calibri'>
            <div className='w-[90%]'>
                <h2 className='text-[30px] text-center  px-4 p-1 my-2 text-orange-400'>Description de l'école</h2>
                <p className='text-gray-600'>
                    Le Lycée d'Excellence NGAGARA se situe dans la commune de Ntahangwa, en Mairie de Bujumbura, dans la zone de Gihosha, Quartier Muyaga Boulevard MWAMBUTSA.
                    <br /><br />
                    Le Lycée d'Excellence NGAGARA est un établissement d'enseignement fondamental et post-fondamental qui s'engage à offrir une éducation de qualité aux élèves de la région Ouest (quatre provinces notamment : BUBANZA, BUJUMBURA, CIBITOKE et BUJUMBURA MAIRIE) sélectionnés au concours organisé par le comité de pilotage des ecoles d'excellence pour les élèves de 6ème année pour l'enseignement fondamental et au concours national des élèves de 9ème année pour l'enseignement post-fondamental.
                    <br /><br />
                    Le Lycée d'Excellence NGAGARA propose un enseignement fondamental ainsi que 4 sections de post-fondamental comprenant :
                    <br />

                    -Section économique
                    <br />
                    -Section bio-chimie et sciences de la terre
                    <br />
                    -Section maths-physique
                    <br />
                    -Section maths-statistiques
                </p>
            </div>
        </div >
    )
}

export default DescriptionEcole
