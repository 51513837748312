import React from 'react'
import DetailEventAdmin from './DetailEventAdmin'
import EvenementNavBars from '../EvenementNavNars/EvenementNavBars'
function DetailEventAdminComponent() {
    return (
        <>
            <EvenementNavBars />
            <DetailEventAdmin/>
        </>
    )
}

export default DetailEventAdminComponent
