/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
function NavBars({ SetisAdmin, isAdmin }) {
    const history = useNavigate();
    const [isClicked, SetisClicked] = useState(false)
    const [imageLoading, SetImageLoading] = useState(true)
    useEffect(() => {
        const handleKeyPress = () => {
            SetisClicked(false)
        };
        window.addEventListener('click', handleKeyPress);
        return () => {
            window.removeEventListener('click', handleKeyPress);
        };
    }, []);
    const id = localStorage.getItem('v');
    const [user, setUser] = useState({});
    console.log(id)
    useEffect(() => {
        if (id) {
            axios.get(`https://irakoze.burundientempsreel.com/api/usergetprofilekk/${id}`).then((response) => {
                setUser(response.data);
                console.log(response.data);
            }).catch((err) => {
                console.log(err.message)
            })
        }
    }, [id])

    const Logout = () => {
        localStorage.removeItem('v');
        localStorage.removeItem('vvvvv');
        SetisAdmin(false)
        history('/');
    }


    const { pathname } = useLocation()
    let AdminAceuil = /^\/AdminAccueil.*/
    let AdminEvent = /^\/Admin\/Evenement.*/
    let AdminEventAjout = /^\/Admin\/AjoutEvenement.*/

    let AdminTemoignage = /^\/Admin\/Temoignage.*/
    let AdminTemoignageStandBuy = /^\/Admin\/StandBuy.*/


    let AdminAnnonces = /^\/Admin\/Annonce.*/
    let AdminAnnoncesAjouter = /^\/Admin\/AjouterAnnonce.*/



    let AdminRessources = /^\/Admin\/Ressources.*/
    let AdminRessourcesAjouter = /^\/Admin\/AjouteRessources.*/

    let AdminMembre = /^\/Admin\/Members.*/
    let AdminMembreAjouter = /^\/Admin\/AjouterMembers.*/

    let AdminContact = /^\/Admin\/Contact.*/
    let AdminContactReseauxSoci = /^\/Admin\/SocialContact.*/
    let AdminContactSuggestion = /^\/Admin\/SuggestionContact.*/








    const [menu, SetMenue] = useState(false);
    const [mobile, SetMobile] = useState(window.innerWidth < 776);
    const [mobile1, SetMobile1] = useState(window.innerWidth < 577);
    const [mobile2, SetMobile2] = useState(window.innerWidth < 374);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 776)
            SetMobile1(window.innerWidth < 577)
            SetMobile2(window.innerWidth < 374)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    useEffect(() => {

        const hundleClick = () => {
            SetMenue(false)
        }
        window.addEventListener('click', hundleClick)
        return () => {
            window.addEventListener('click', hundleClick)
        }
    }, [])

    return (
        <div className={`bg-gray-300 ${mobile ? 'justify-between' : 'justify-end'}  flex  items-center px-2 h-[13vh]  relative border-b-2 border-orange-500`}>

            <div
                className={`mx-2 cursor-pointer h-max  p-1 text-white rounded bg-gradient-to-r from-orange-500 to-orange-300 hover:from-orange-700 hover:to-orange-500 transition-all ${mobile ? 'block' : 'hidden'}`}>


                <div onClick={(e) => {
                    SetMenue(!menu);
                    e.stopPropagation()
                }}>

                    {menu ? (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </>
                    ) : (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </>
                    )}



                </div>
            </div>

            <div className={`w-[50px] h-[50px] ${mobile ? 'block' : 'hidden'} mt-1 relative rounded overflow-hidden `}>

                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                <img draggable='false' onLoad={() => {
                    setTimeout(() => {
                        SetImageLoading(false)
                    }, 1000)
                }}
                    src="https://irakoze.burundientempsreel.com/uploads/Photo/LENGA2.png" alt="" className='block w-full h-full object-cover object-center' />

            </div>
            <div className="flex justify-end items-center h-full  mr-1 px-3">
                <div className="logos w-[50px] h-[50px]  rounded-full relative overflow-hidden ">

                    {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                            SetImageLoading(false)
                        }, 1000)
                    }}

                        src={`https://irakoze.burundientempsreel.com/uploads/photosuser/${user.photo}`} alt="" className='w-full h-full object-cover' />


                </div>
                <div className={`font-serif ${mobile1 ? 'hidden' : 'block'}`}>
                    {user.nom}{' '}{user.prenom}
                </div>
            </div>
            <button onClick={Logout} className={`mr-1  flex flex-col items-center transition-all px-4   cursor-pointer ${mobile2 ? 'w-[40px] h-[40px] rounded-full text-white justify-center items-center bg-red-600 flex' : 'hover:border-b-2 hover:border-blue-700 rounded-t hover:bg-orange-400 hover:text-white'}`}>
                <i className={`bi  bi-box-arrow-left`}></i>
                <p className={`${mobile2 ? 'hidden' : 'block'}`}>Déconnexion</p>
            </button>



            <div className={`w-[14em] ${menu ? 'left-0' : '-left-full'} transition-all fixed z-[200000000000] top-[13vh]  h-full bg-gray-300 border-r-2 border-orange-300  flex-col items-center ${mobile ? 'flex' : 'hidden'} `}>



                <div className="w-full h-[75%] overflow-x-hidden overflow-y-auto border-t-2 border-orange-500">
                    <div className="w-full h-100% font-serif">


                        {AdminAceuil.test(pathname) || pathname == '/' ? (
                            <Link to='/AdminAccueil' className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Acceuil</p>
                            </Link>
                        ) : (
                            <Link to='/AdminAccueil' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Acceuil</p>
                            </Link>
                        )}

                        {AdminEvent.test(pathname) || AdminEventAjout.test(pathname) ? (
                            <Link to='/Admin/Evenement' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                                <p className='sizeText'>Evénement</p>
                            </Link>

                        ) : (
                            <Link to='/Admin/Evenement' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                                <p className='sizeText'>Evénement</p>
                            </Link>
                        )}

                        {AdminTemoignage.test(pathname) || AdminTemoignageStandBuy.test(pathname) ? (
                            <Link to="/Admin/Temoignage" className='flex   justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600  transition-all text-fuchsia-50  hover:text-fuchsia-50 '>
                                <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                                <p className='sizeText'>Témoignage</p>
                            </Link>
                        ) : (
                            <Link to="/Admin/Temoignage" className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                                <p className='sizeText'>Témoignage</p>
                            </Link>
                        )}


                        {AdminAnnonces.test(pathname) || AdminAnnoncesAjouter.test(pathname) ? (
                            <Link to="/Admin/Annonce" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Annonce</p>
                            </Link>
                        ) : (
                            <Link to="/Admin/Annonce" className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Annonce</p>
                            </Link>
                        )}


                        {AdminRessources.test(pathname) || AdminRessourcesAjouter.test(pathname) ? (
                            <Link to="/Admin/Ressources" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                                <p className='sizeText'>Ressource</p>
                            </Link>
                        ) : (
                            <Link to="/Admin/Ressources" className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                                <p className='sizeText'>Ressource</p>
                            </Link>
                        )}
                        {AdminMembre.test(pathname) || AdminMembreAjouter.test(pathname) ? (
                            <Link to="/Admin/Members" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                <i class="bi bi-people-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Membre</p>
                            </Link>
                        ) : (
                            <Link to="/Admin/Members" className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-people-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Membre</p>
                            </Link>
                        )}
                        {AdminContact.test(pathname) || AdminContactReseauxSoci.test(pathname) || AdminContactSuggestion.test(pathname) ? (
                            <Link to="/Admin/Contact" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Contact</p>
                            </Link>
                        ) : (
                            <Link to="/Admin/Contact" className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Contact</p>
                            </Link>
                        )}
                    </div>
                </div>

            </div>
        </div >
    )
}

export default NavBars
