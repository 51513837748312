/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import { FadeLoader, BarLoader } from 'react-spinners';


function Contact() {
    const [loading, SetLoading] = useState(true)

    const [spinnerButton, SetSpinnerButton] = useState(false)

    const [isNom, setIsNom] = useState('');
    const [animationClassNom, setAnimationClassNom] = useState('');

    const [isPrenom, setIsPrenom] = useState('');
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');

    const [isEmail, setIsEmail] = useState('');
    const [animationClassEmail, setAnimationClassEmail] = useState('');

    const [isTextarea, setIsTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const [isPending, setPending] = useState(true)

    function validateEmail(email) {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (isNom.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassNom('');
            }, 3000);
            return;
        }

        if (isPrenom.trim() === '') {
            toast.warning('Le prénom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassPrenom('');
            }, 3000);
            return;
        }

        if (isEmail.trim() === '') {
            toast.warning("L'adresse email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassEmail('');
            }, 3000);
            return;
        }
        if (!validateEmail(isEmail)) {
            toast.warning("L'adresse email doit être valide", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassEmail('');
            }, 3000);
            return;
        }

        if (isTextarea.trim() === '') {
            toast.warning('Votre commentaire est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            return;
        }
        SetSpinnerButton(true)
        axios
            .post('https://irakoze.burundientempsreel.com/api/addsuggestion', {
                nom: isNom,
                prenom: isPrenom,
                email: isEmail,
                suggestion: isTextarea
            })
            .then((response) => {
                toast.success(response.data);
                setIsNom('');
                setIsPrenom('');
                setIsEmail('');
                setIsTextarea('');

                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.error);
                }

            }).finally(() => {
                SetSpinnerButton(false);
            })
    };
    const [coordonne, setcoordonne] = useState();

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/findcoordonne').then((response) => {
            setcoordonne(response.data)
            setTimeout(() => {
                setPending(false)
            }, 100)
        }).catch((error) => {
            console.log(error.message)
        }).finally(() => {
            SetSpinnerButton(false);
        });
    }, [])
    const sendRef = useRef(null)

    return (
        <div className=" h-max flex justify-center p-2 flex-col items-center">
            {isPending && <Loading />}
            <h2 className="p-2 text-center mb-[1em] text-[25px]">Formulaire de Suggestion</h2>
            <div className=" border m-3 p-2 rounded w-[40em] h-max contactBlock">
                <form onSubmit={handleSubmit}>
                    <div className="flex mb-6 responsive31">
                        <div className="transition-all flex w-[100%] justify-between conctat">
                            <div className="relative h-11 w-1/2 contactInput mr-2">
                                <input
                                    value={isNom}
                                    onInput={(e) => setIsNom(e.target.value)}
                                    className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassNom}`}
                                    placeholder=" "
                                />
                                <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Nom
                                </label>
                            </div>

                            <div className="relative h-11 contactInput w-1/2 mr-2">
                                <input
                                    value={isPrenom}
                                    onInput={(e) => setIsPrenom(e.target.value)}
                                    className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassPrenom}`}
                                    placeholder=" "
                                />
                                <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Prénom
                                </label>
                            </div>

                            <div className="relative h-11 contactInput w-1/2">
                                <input
                                    value={isEmail}
                                    onInput={(e) => setIsEmail(e.target.value)}
                                    className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassEmail}`}
                                    placeholder=" "
                                />
                                <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Email
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="relative h-full w-full mt-[2em]">
                        <textarea
                            value={isTextarea}
                            onChange={(e) => {
                                setIsTextarea(e.target.value);
                                setAutoScrollHeight(e.target.scrollHeight);
                                setValueTextarea(e.target.value.trim().length);
                                if (!valueTextarea) {
                                    setAutoScrollHeight(50);
                                }
                            }}
                            placeholder=" "
                            style={{ height: `${autoScrollHeight}px` }}
                            className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                        />
                        <label
                            className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                        >
                            Suggestion
                        </label>
                    </div>
                    <div className="flex justify-end items-center">
                        {spinnerButton ? (
                            <>

                                <label htmlFor="send" ref={sendRef} className="w-max relative pointer-events-none   flex justify-center items-center p-1 bg-gray-900 rounded cursor-pointer px-5 py-1 text-white ">
                                    <div className='absolute bg-transparent pt-5  pl-5 w-full h-full flex justify-center items-center z-50'>
                                        <FadeLoader
                                            color="rgba(255, 255, 255)"
                                            height={10}
                                            margin={-9}
                                            radius={100}
                                            speedMultiplier={1}
                                            width={1}
                                        /></div>
                                    <span>Envoyer</span>
                                    <i title="Envoyer" className="bi bi-send ml-3 "></i>
                                </label>
                            </>
                        ) : (
                            <>
                                <label htmlFor="send" ref={sendRef} className="w-max  flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white ">
                                    <span>Envoyer</span>
                                    <i title="Envoyer" className="bi bi-send ml-3 "></i>
                                </label>
                            </>
                        )}
                        <input type="submit" id="send" hidden className="w-max hidden justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white" />
                    </div>
                </form>
            </div>
            <div className="w-full flex flex-wrap justify-around p-3">
                {coordonne && (
                    <div className="m-2 b responsive p-2 flex justify-left flex-col">
                        <h2 className="p-2 text-left text-[25px]">Coordonnées de contact</h2>
                        <div>
                            <p>Adresse physique : {coordonne.adress ? (<>{coordonne.adress}</>) : (<><BarLoader color="orange" width={20} height={2} /></>)}</p>
                            <p>Le numéro de téléphone : {coordonne.tel ? (<>{coordonne.tel}</>) : (<><BarLoader color="orange" width={20} height={2} /></>)}</p>
                            <p>L'adresse e-mail : : {coordonne.email ? (<>{coordonne.email}</>) : (<><BarLoader color="orange" width={20} height={2} /></>)}</p>
                        </div>
                    </div>
                )}
                <div className="m-2 b responsive p-2  justify-left flex-col hidden">
                    <h2 className="p-2 text-left text-[25px]">Réseaux sociaux</h2>
                    <div>
                        <p>Facebook : ExcellenceNgagara</p>
                        <p>Instagram : ExcellenceNgagara</p>
                        <p>Twitter : @ExcellenceNgagara</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
