import React from 'react'
import DetailTemoignage from './DetailTemoignage'
import NavBarsTemoignage from '../TemoignanegNavBars/NavBarsTemoignage'
function DetailTemoignageComponent() {
    return (
        <>
            <NavBarsTemoignage />
            <DetailTemoignage />
        </>
    )
}

export default DetailTemoignageComponent
