import React from 'react'

function Partenariat() {
    return (
        <div className='w-full h-max  justify-center items-center hidden'>
            <div className='w-[90%]'>
                <h2 className='text-[30px] text-center  px-4 p-1 my-2 text-orange-400'>Partenaires ou collaborations </h2>

                <p className='text-gray-600'>Nous considérons les parents et les tuteurs comme des partenaires précieux dans l'éducation de nos élèves. Nous encourageons une communication ouverte et régulière avec les parents, organisons des réunions et des événements spéciaux pour les impliquer dans la vie scolaire et travaillons ensemble pour le succès de chaque élève.</p>
                <p className='text-gray-600'>
                    Nous avons établi des partenariats solides avec des universités de premier plan afin de permettre à nos élèves d'accéder à des programmes d'études
                    supérieures de qualité et de bénéficier de possibilités de recherche avancées.
                </p>
                <p className='text-gray-600'>
                    Nous avons établi des liens avec des écoles à l'étranger afin d'offrir à nos étudiants des opportunités d'échanges culturels et linguistiques. Ces programmes d'échange leur permettent de découvrir de nouvelles cultures, d'améliorer leurs compétences linguistiques et d'élargir leurs horizons.
                </p>

            </div>
        </div>
    )
}

export default Partenariat
