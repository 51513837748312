import React from 'react'
import AddMoreRessource from './AddMoreRessource'
import NavBarsRessource from '../NavBarsRessour/NavBarsRessource'
function AddMoreRessourceComponent() {
    return (
        <>

            <NavBarsRessource />
            <AddMoreRessource />

        </>
    )
}

export default AddMoreRessourceComponent