import React from 'react'
import EvenementAdiminComponent from '../../../component/Admin/EvenementAdmin/EvenementGereAdmin/EvenementAdiminComponent'
function EvenementAdimPage() {
    return (
        <EvenementAdiminComponent />
            
       
    )
}

export default EvenementAdimPage
