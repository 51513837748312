import React from 'react'
import MembrsNavBars from '../MembleNavBArs/MembrsNavBars'
import AffchageMembre from './AffchageMembre'
function AffichageMembresComponent() {
  return (
    <>
      <MembrsNavBars />
      <AffchageMembre />
    </>
  )
}

export default AffichageMembresComponent
