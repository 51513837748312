/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners'
import Loading from '../../../Loading/Loading';
const Options = [
    { value: 'Encadreurs', label: 'Encadreurs' },
    { value: 'Infirmière ', label: 'Infirmière' },
    { value: 'Aide econome', label: 'Aide econome' },
    { value: 'Economes', label: 'Economes' },
    { value: 'Enseignants', label: 'Enseignants' },
    { value: 'Bibliotequaires', label: 'Bibliotequaires' },
    { value: 'Secretaires', label: 'Secretaires' },
    { value: 'Prefet de displine', label: 'Prefet de displine' },
    { value: 'Prefet des études', label: 'Prefet des études' },
    { value: 'Directeur', label: 'Directeur' }
]

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderBottom: '2px solid gray',
        borderRadius: '0px',
        backgroundColor: "transparent",
        top: '7px',
        with: '100%',
        boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
        borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? '#333' : '#666',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        position: 'static',
        zIndex: '10000',
    }),
};


function ModifierMembres() {
    const [isPending, setPending] = useState(true)
    const [spinnerButton, SetSpinnerButton] = useState(false)
    const { id } = useParams();
    const history = useNavigate();
    const [fonction, setFonction] = useState('');
    const [nom, Setnom] = useState('')
    const [animationClassnom, setanimationClassnom] = useState('');

    const [prenom, SetPrenom] = useState('')
    const [animationClassPrenom, setanimationClassPrenom] = useState('');

    const [tele, Settele] = useState('')
    const [animationClasstele, setAnimationClasstele] = useState('');


    const [isEmail, SetisEmail] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;

    const [isSelectValue, SetisSelectValue] = useState('')
    const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');

    const [animationClassProfil, setanimationClassProfil] = useState('');
    const [file, setFile] = useState(null);
    const [photo, setPhoto] = useState(null);


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getonmember/${id}`)
            .then((response) => {
                Setnom(response.data.nom);
                SetPrenom(response.data.prenom);
                SetisEmail(response.data.email);
                Settele(response.data.tel);
                setPhoto(response.data.file);
                setFonction(response.data.fonction);
                setTimeout(() => {
                    setPending(false)
                }, 1000);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (nom.trim() == '') {
            toast.warning("Le nom du membre est obligatoire !!", {
                autoClose: 2000
            });
            setanimationClassnom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setanimationClassnom(' ')
            }, 3000)
            return;
        } else if (prenom.trim() == '') {
            toast.warning('Le prenom du membre est obligatoire !!', {
                autoClose: 2000
            });
            setanimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setanimationClassPrenom(' ')
            }, 3000)
            return;
        }
        else if (tele.trim() == '') {
            toast.warning("Le téléphone du membre est obligatoire", {
                autoClose: 2000
            });
            setAnimationClasstele('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClasstele(' ')
            }, 3000)
            return;
        }
        else if (isEmail.trim() == '') {
            toast.warning("L'address email du membre est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            return;
        }
        else if (!isEmail.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX  border-red-600 text-red-600')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            return;
        }
        SetSpinnerButton(true)
        const formData = new FormData();
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('tel', tele);
        formData.append('email', isEmail);
        (isSelectValue) ? (formData.append('fonction', isSelectValue.value)) : (formData.append('fonction', fonction));
        if (file !== null) {

            formData.append('file', file);
            axios.put(`https://irakoze.burundientempsreel.com/api/member/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((Response) => {
                // Handle success response here
                history('/Admin/Members');
            }).catch((error) => {
                if (error.response) {
                    toast.warning(error.response.data.error, {
                        autoClose: 2000
                    });
                }
            }).finally(() => {
                SetSpinnerButton(false)
            });
        } else {
            axios.put(`https://irakoze.burundientempsreel.com/api/member/${id}`, formData).then((Response) => {
                // Handle success response here
                history('/Admin/Members');
            }).catch((error) => {
                if (error.response) {
                    toast.warning(error.response.data.error, {
                        autoClose: 2000
                    });
                }
            });

        }
    }

    const [loading, SetLoading] = useState(true)


    return (
        <div className=' bg-white  min-h-[75vh] flex justify-center items-center'>
            {isPending && <Loading />}
            <div className="bg-white border border-orange-600 rounded-2xl m-3 p-4  w-[30em] h-max wimax ">
                <h2 className=' p-2 text-center  text-[30px] text-orange-500 animeBorder'>Modifier un  membre</h2>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col mb-6  p-1">
                        <div className="flex w-full justify-between responsivLogin my-3">
                            <div class="relative h-11 w-full bloksInput mr-2  my-2">
                                <input value={nom} onInput={(e) => Setnom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassnom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Nom
                                </label>
                            </div>

                            <div class="relative h-11 w-full bloksInput mr-2  my-2">
                                <input value={prenom} onInput={(e) => SetPrenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Prenom
                                </label>
                            </div>
                        </div>

                        <div className="flex w-full justify-between responsivLogin my-3">

                            <div class="relative h-11 w-full bloksInput mr-2  my-2">
                                <input value={tele} onInput={(e) => Settele(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasstele}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Téléphone
                                </label>
                            </div>




                            <div class="relative h-11 w-full bloksInput  my-2 mr-2 ">
                                <input value={isEmail} onInput={(e) => SetisEmail(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Email
                                </label>
                            </div>

                        </div>


                        <p>Fonction:<span>{(isSelectValue) ? isSelectValue.value : fonction}</span></p>
                        <div className="flex w-full transition-all h-max justify-between items-center responsMembreModifier my-3 ">
                            <div class="relative h-11 w-1/2 transition  responsMembreModifierSelect mr-2  my-2">
                                <div className={`flex flex-col h-11 w-1/2 relative border-b-2 responsive31_temoignSelect ${animationClassSelecteValue}`}>
                                    <Select onChange={SetisSelectValue} defaultValue={isSelectValue} options={Options} styles={customStyles} >
                                    </Select>
                                </div>
                            </div>

                            <div class="relative h-11 w-1/2 transition  responsMembreModifierFile  mt-2 mr-2 ">
                                <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
                                <label htmlFor="profils" className={` transition-all p-1 bg-orange-400 hover:bg-orange-600 py-3 rounded cursor-pointer text-white ${animationClassProfil}`}>Selection le profile</label>
                            </div>
                        </div>



                        <div className="relative top-6">
                            <input type="file" id='profils' name='file' hidden accept="image/*" onChange={handleFileChange} />
                            <div className={`animeBorder relative p-1 transition-all   top-4  rounded border-4 border-dotted border-orange-500 overflow-hidden w-full wima ${(file == null && photo == null) ? 'h-[0px] opacity-0 ' : 'h-[300px] opacity-100'}`}>


                                {file ? (
                                    <>
                                        {loading && <div className="absolute w-full h-full  inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                        }
                                        <img onLoad={() => {
                                            setTimeout(() => {
                                                SetLoading(false)
                                            }, 1000)
                                        }} src={URL.createObjectURL(file)} alt="Image" className=' w-full h-full rounded object-contain' />

                                    </>) : (photo && (
                                        <>
                                            {loading && <div className="absolute w-full h-full  inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                            }
                                            <img onLoad={() => {
                                                setTimeout(() => {
                                                    SetLoading(false)
                                                }, 1000)
                                            }} src={`https://irakoze.burundientempsreel.com/uploads/membres/${photo}`} alt="Image" className=' w-full h-full rounded object-contain' />

                                        </>
                                    ))}


                            </div>
                        </div>
                    </div>







                    {spinnerButton ? (
                        <>
                            <div className="flex justify-end items-center mt-10 relative pointer-events-none opacity-80">
                                <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                                    <FadeLoader
                                        color="rgb(255, 255, 255)"
                                        height={10}
                                        margin={-9}
                                        radius={100}
                                        speedMultiplier={1}
                                        width={1}
                                    /></div>
                                <input type="submit" id="send" value="Modifier" class="transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex justify-end items-center mt-10">
                                <label htmlFor='sende' className="w-max cursor-pointer  flex justify-end p-1 bg-orange-600 rounded  px-2 py-1 text-white">
                                    <input type="submit" className='pointer-events-none' id='sende' value="Modifier" />
                                    <i title="Modifier" class="bi bi-send ml-2 "></i>
                                </label>
                            </div>
                        </>
                    )}

                </form>
            </div>
        </div >
    )
}

export default ModifierMembres

