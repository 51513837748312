/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import Loading from "../../../Loading/Loading";
import PropTypes from 'prop-types'

function GrerRessourcesAdmin() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [titre, setTitre] = useState("");
  const [eventSearch, setEventSearch] = useState([]);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    const iduser = localStorage.getItem("userid");

    axios
      .get(`https://irakoze.burundientempsreel.com/api/getallresources?page=${currentPage}`)
      .then((response) => {
        const { sources, totalPages } = response.data;
        setData(sources);
        setTotalPages(totalPages);
        setTimeout(() => {
          setPending(false);
        }, 100);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? "text-sm font-medium leading-none cursor-pointer bg-orange-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-yellow-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2"
              : "text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-yellow-400 border-t border-transparent hover:border-yellow-400 pt-3 mr-4 px-2"
          }
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  function handleDelete(id, position) {
    Swal.fire({
      title: "Confirmation",
      text: "Etes-vous sûr que vous voulez supprimer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "SUPPRIMER",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "swal-confirm-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://irakoze.burundientempsreel.com/api/deletesource/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success("la resource a été supprimé avec succès");
              const allData = [...data];
              allData.splice(position, 1);
              setData(allData);
            } else {
              toast.error(
                "Une erreur s'est produite lors de la suppression de la resource"
              );
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(
              "Une erreur s'est produite lors de la suppression de la resource"
            );
          });
      }
    });
  }





  const [searching, setSearching] = useState(false);
  const search = (titre) => {
    if (titre) {
      setSearching(true); // Indiquer que la recherche est en cours
      axios
        .get("https://irakoze.burundientempsreel.com/api/source/bytitre", {
          params: { titre: titre },
        })
        .then((response) => {
          if (response.data === "événement introuvable !") {
            toast.error("resourse introuvable", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            eventSearch.length = 0
            setTitre("")
          } else {
            setEventSearch(response.data);
          }
          setSearching(false); // Indiquer que la recherche est terminée
        })
        .catch((err) => {
          console.error("Event not found!");
          setSearching(false); // Indiquer que la recherche est terminée
        });
    } else {
      eventSearch.length = 0;
    }
  };




  const [loadingImages, setLoadingImages] = useState([]);

  const handleImageLoad = (index) => {

    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      updatedLoadingImages[index] = true;
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 50000);
      return updatedLoadingImages;
    });

  };





  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  const [mobile, SetMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 600)
    }
    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])
  return (
    <div className=" bg-white min-h-[75vh] flex flex-col items-end">
      {isPending && <Loading />}

      {data.length > 0 && (
        <div className="pr-10 bg-gray-50 p-1 m-1 rounded">
          <p className="text-gray-500 font-serif">Rechercher par titre</p>
          <div className="search py-1">

            <form>
              <input
                type="text"
                className="p-1 border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-orange-500"
                id=""
                placeholder="Rechercher..."
                value={titre}
                onChange={(e) => {
                  setTitre(e.target.value);
                  search(e.target.value);
                }}
              />
            </form>

          </div>
        </div>
      )}


      <div className="w-full h-max mb-5 bg-white p-1 flex flex-wrap justify-center">
        {eventSearch.length > 0 ? (
          eventSearch.map((eventSearch, index) => (
            <div className={`bg-white ${mobile ? 'flex-auto' : 'w-[18em]'}  p-3 m-1 rounded  border  border-orange-700`}>
              <div className={`w-full  ${mobile ? 'flex-auto h-full' : 'h-[19em] '} overflow-hidden p-1 bg-transparent`}>
                {eventSearch.file && (() => {
                  const extension = eventSearch.file.split('.').pop();
                  if (extension === 'pdf') {

                    return (
                      <div>PDF introuvable</div>
                    );
                  } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                    return (

                      <>
                        <div key={eventSearch.id} className='relative border h-full w-full '>
                          {loadingImages[index] ? (
                            <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                          ) :
                            (
                              <ReactPlayer
                                url={`https://irakoze.burundientempsreel.com/uploads/resources/${eventSearch.file}`}
                                controls
                                className=" inline object-cover object-center w-full h-full"

                              />
                            )}

                        </div>
                      </>


                    );
                  } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                    return (
                      <div key={eventSearch.id} className='relative  h-full w-full '>
                        {loadingImages[index] ? (
                          <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                        ) :
                          (
                            <LoadingImage
                              src={`https://irakoze.burundientempsreel.com/uploads/resources/${eventSearch.file}`}
                              className="w-full h-full border-none object-contain"
                              onLoad={() => handleImageLoad(index)}
                            />
                          )}

                      </div>
                    );
                  }
                  return null;
                })()}
              </div>
              <div className="trasition-all w-full hover:text-orange-500 mt-3">
                <Link
                  to={`/Admin/Ressources/Detail/${eventSearch.id}`}
                  className="text-center font-serif p-1  w-full h-full text-[18px] "
                >
                  {eventSearch.titre}
                </Link>
              </div>
              <p className="text-gray-500 my-3 ">
                {eventSearch.description && eventSearch.description.length > 300
                  ? eventSearch.description.slice(0, 300) + "..."
                  : eventSearch.description}
              </p>

              <div className=" flex justify-between p-1 mt-9">
                <Link
                  to={`/Admin/Ressources/Editor/${eventSearch.id}`}
                  title="Modifier"
                  className="bi bi-box-arrow-in-down-left bg-orange-500 hover:bg-orange-600 text-white py-1 px-2  w-[30px] h-[30px] rounded"
                ></Link>
                <Link
                  to={`/Admin/Ressources/AjoutPlus/${eventSearch.id}`}
                  title="Ajouter les autre fichiers supplementaires de ce fichiers principal"
                  className="transition-all  bi bi-patch-plus-fill px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white  rounded"
                ></Link>
                <button
                  title="Supprimer"
                  className=" transition-all  bi bi-trash-fill bg-red-500 hover:bg-red-600 text-white w-[30px] h-[30px] rounded"
                  onClick={() => handleDelete(eventSearch.id)}
                ></button>
              </div>
            </div>
          ))) : data.length > 0 ? (
            data.map((data, position) => {
              return (
                <div className={`bg-white ${mobile ? 'flex-auto' : 'w-[18em]'}  p-3 m-1 rounded-2xl  border  border-orange-700`}>
                  <div className={`w-full  ${mobile ? 'flex-auto h-full' : 'h-[19em] '} overflow-hidden p-1 bg-transparent`}>
                    {data.file && (() => {
                      const extension = data.file.split('.').pop();
                      if (extension === 'pdf') {

                        return (
                          <div>PDF introuvable</div>
                        );
                      } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                        return (

                          <>
                            <div key={data.id} className='relative border h-full w-full '>
                              {loadingImages[position] ? (
                                <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                              ) :
                                (
                                  <ReactPlayer
                                    url={`https://irakoze.burundientempsreel.com/uploads/resources/${data.file}`}
                                    controls
                                    className=" inline object-cover object-center w-full h-full"

                                  />
                                )}

                            </div>
                          </>


                        );
                      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                        return (
                          <div key={data.id} className='relative  h-full w-full '>
                            {loadingImages[data] ? (
                              <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                            ) :
                              (
                                <LoadingImage
                                  src={`https://irakoze.burundientempsreel.com/uploads/resources/${data.file}`}
                                  className="w-full h-full border-none object-contain"
                                  onLoad={() => handleImageLoad(position)}
                                />
                              )}

                          </div>
                        );
                      }
                      return null;
                    })()}
                  </div>
                  <div className="trasition-all w-full hover:text-orange-500 mt-3">
                    <Link
                      to={`/Admin/Ressources/Detail/${data.id}`}
                      className="text-center font-serif p-1  w-full h-full text-[18px] "
                    >
                      {data.titre}
                    </Link>
                  </div>
                  <p className="text-gray-500 my-3  ">
                    {data.description && data.description.length > 100
                      ? data.description.slice(0, 100) + "..."
                      : data.description}
                  </p>
                  <div className=" flex justify-between p-1 mt-9">
                    <Link
                      to={`/Admin/Ressources/Editor/${data.id}`}
                      title="Modifier"
                      className="bi bi-box-arrow-in-down-left bg-orange-500 hover:bg-orange-600 text-white py-1 px-2 rounded"
                    ></Link>
                    <Link
                      to={`/Admin/Ressources/AjoutPlus/${data.id}`}
                      title="Ajouter les autre fichiers supplementaires de ce fichiers principal"
                      className="transition-all  bi bi-patch-plus-fill px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white  rounded"
                    ></Link>
                    <button
                      title="Supprimer"
                      className=" transition-all  bi bi-trash-fill bg-red-500 hover:bg-red-600 text-white w-[30px] h-[30px] rounded"
                      onClick={() => handleDelete(data.id, position)}
                    ></button>
                  </div>
                </div>
              );
            })
          ) : (
          <body class="p-32 bg-slate-50 dark:bg-slate-600">
            <div class="container flex flex-wrap justify-between items-center mx-auto bg-white rounded-md dark:bg-slate-500">
              <div class="text-center border-dashed border-2 border-slate-200 dark:border-slate-100 rounded-md w-full p-20">
                <i class='bx bxs-contact bx-lg mb-5 dark:text-white'></i>
                <p class="text-xl mb-2 uppercase font-bold dark:text-white">Pas resource disponible</p>
                <spam class="text-m text-slate-400 block mb-10 dark:text-slate-50"> Commencez par la créer un resource si est disponible </spam>
                <Link to="/Admin/AjouteRessources" class="bg-orange-400 rounded-full px-5 py-3 text-white hover:bg-orange-500 w-52">Créer</Link>
              </div>
            </div>
          </body>
        )}
      </div>
      {data.length > 0 && (
        <div className=" w-[25em]">
          <div class=" w-full h-full flex justify-around items-center p-2">
            <button
              onClick={previousPage}
              disabled={currentPage === 1}
              class=" transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
            >
              <a href="#" class="mr-2">
                &laquo;
              </a>
              <p>Precedant</p>
            </button>
            <button class="pagination-link">{generatePageNumbers()}</button>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              class=" transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
            >
              <p>Suivant</p>
              <a href="#" class="ml-2">
                &raquo;
              </a>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GrerRessourcesAdmin;
