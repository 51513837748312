/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import "./App.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

// Pour le visiteur
import NavBarsVisitors from './component/Visitor/NavBarsVisitors/NavBarsVisitors';
import FooterVisitor from './component/Visitor/FooterVisitor/FooterVisitor';

import HomeVisitorPage from './page/VisitorPage/HomeVisitorPage';
import EvenemntVisitorPage from './page/VisitorPage/EvenementPage/EvenemntVisitorPage';
import DetailEventVisitoPage from './page/VisitorPage/EvenementPage/DetailEventVisitoPage';
import FormulaireTemoignVisitores from './component/Visitor/Temoignage/FormulaireTemoignVisitores';
import TemoignagePage from './page/VisitorPage/TemoignagePage/TemoignagePage';
import AnnoncePage from './page/VisitorPage/AnnoncePage/AnnoncePage';
import RessourcePage from './page/VisitorPage/RessourcePage/RessourcePage';
import DetailAnnoncePage from './page/VisitorPage/AnnoncePage/DetailAnnoncePage';
import DetailResourcePage from './page/VisitorPage/RessourcePage/DetailResourcePage';
import Contact from './component/Visitor/Contact/Contact';
import AproposPage from './page/VisitorPage/AproposPage/AproposPage';



// pour admin
import Login from './LoginPage/Login';

import NavBarsSlide from './component/Admin/NavBarsSlide/NavBarsSlide';
import RegisterAdmin from './component/Visitor/RegisterAdmin/RegisterAdmin';
import CopyRight from './component/Visitor/CopyRight/CopyRight';
import NavBars from './component/Admin/NavBars/NavBars';


import AcceuilAdminPage from './page/AdminPage/AcceuilAdminPage/AcceuilAdminPage';
import EvenementAdimPage from './page/AdminPage/EvenementAdminPage/EvenementAdimPage';
import DetailEvementAdminPage from './page/AdminPage/EvenementAdminPage/DetailEvementAdminPage';
import AjoutEvenementAdminPage from './page/AdminPage/EvenementAdminPage/AjoutEvenementAdminPage';
import TemoignageAdmimPage from './page/AdminPage/TemoignagePge/TemoignageAdmimPage';
import DetailTemoigePage from './page/AdminPage/TemoignagePge/DetailTemoigePage';
import StanbdBuyTemoignagePage from './page/AdminPage/TemoignagePge/StanbdBuyTemoignagePage';
import StandBuyDetailTemoignagePage from './page/AdminPage/TemoignagePge/StandBuyDetailTemoignagePage';
import AddMoresPage from './page/AdminPage/EvenementAdminPage/AddMoresPage';

import GereAnnonceAdminPage from './page/AdminPage/AnnonceAdminPage/GereAnnonceAdminPage';
import DetailAdminAnnoncePage from './page/AdminPage/AnnonceAdminPage/DetailAdminAnnoncePage';
import AjouterAdminAnnoncesPage from './page/AdminPage/AnnonceAdminPage/AjouterAdminAnnoncesPage';
import RessourceAdminPage from './page/AdminPage/RessourceAdminPage/RessourceAdminPage';
import AjouteRessourcesAdminPAge from './page/AdminPage/RessourceAdminPage/AjouteRessourcesAdminPAge';
import EditeEvenementComponent from './component/Admin/EvenementAdmin/EvenementGereAdmin/EditeEvenementComponent';
import EditorAnnonceAdminPAge from './page/AdminPage/AnnonceAdminPage/EditorAnnonceAdminPAge';
import EditeRessourcePage from './page/AdminPage/RessourceAdminPage/EditeRessourcePage';
import DetailResourceAdminPAge from './page/AdminPage/RessourceAdminPage/DetailResourceAdminPAge';
import AddMoreRessourcePAge from './page/AdminPage/RessourceAdminPage/AddMoreRessourcePAge';
import AddMoreAnnonceAdminPage from './page/AdminPage/AnnonceAdminPage/AddMoreAnnonceAdminPage';
import CoordonnerPhysiquePage from './page/AdminPage/ContactAdminPAge/CoordonnerPhysiquePage';
import ModifierContactPage from './page/AdminPage/ContactAdminPAge/ModifierContactPage';
import ReseauxSocialPage from './page/AdminPage/ContactAdminPAge/ReseauxSocialPage';
import HoraireOuverturePage from './page/AdminPage/ContactAdminPAge/HoraireOuverturePage';
import ModifierHorairePage from './page/AdminPage/ContactAdminPAge/ModifierHorairePage';
import SuggestionPage from './page/AdminPage/ContactAdminPAge/SuggestionPage';
import AfficheMembrePage from './page/AdminPage/Membre/AfficheMembrePage';
import AjouteMembresPage from './page/AdminPage/Membre/AjouteMembresPage';
import ModifierMembresComponent from './component/Admin/Membre/AffchageMembre/ModifierMembresComponent';
import Profile from './component/Admin/Paramettre/Profil/Profile';
import ModifierProfil from './component/Admin/Paramettre/Profil/ModifierProfil';
import axios, { all } from 'axios';
import StandBuyDetailComponent from './component/Admin/TemoignageAdmin/StandBuyAdmin/StandBuyDetailComponent';
import PageNotFound from './page/PageNotFound';

function App() {
  const [activeWebSite, setactiveWebSite] = useState(false);


  const [isAdmin, SetisAdmin] = useState(false)

  useEffect(() => {
    AOS.init();
  })

  const LoginWithProps = () => {
    return <Login isAdmin={isAdmin} SetisAdmin={SetisAdmin} />;
  };
  const token = localStorage.getItem("vvvvv");
  useEffect(() => {
    if (token) {
      axios
        .get("https://irakoze.burundientempsreel.com/api/auth", {
          headers: {
            accessToken: token,
          },
        })
        .then((response) => {
          if (response.data.error) {
            SetisAdmin(false);
          } else {
            SetisAdmin(true);
          }
        });
    }
  }, [token]);
  return isAdmin ? (
    <>
      <Router>
        <div className='w-[100%] h-[100vh] flex overflow-hidden font-serif'>
          <NavBarsSlide />
          <div className='w-[100%] h-[100vh]  overflow-hidden'>
            <NavBars isAdmin={isAdmin} SetisAdmin={SetisAdmin} />
            <div className="h-[87%] w-full  overflow-x-hidden overflow-y-auto">
              <Routes>
                <Route path='/AdminAccueil' Component={AcceuilAdminPage} />
                <Route path='/' Component={AcceuilAdminPage} />
                <Route path='/Admin/Evenement' Component={EvenementAdimPage} />
                <Route path='/Admin/Evenement/Detail/:id' Component={DetailEvementAdminPage} />
                <Route path='/Admin/Evenement/Editor/:id' Component={EditeEvenementComponent} />
                <Route path='/Admin/AjoutEvenement' Component={AjoutEvenementAdminPage} />
                <Route path='/Admin/Evenement/Ajouter/:id' Component={AddMoresPage} />


                <Route path='/Admin/Temoignage/' Component={TemoignageAdmimPage} />
                <Route path='/Admin/Temoignage/Detail/:id' Component={DetailTemoigePage} />
                <Route path='/Admin/StandyBuy' Component={StanbdBuyTemoignagePage} />
                <Route path='/Admin/StandyBuy/Detail/:id' Component={StandBuyDetailTemoignagePage} />


                <Route path='/Admin/Annonce' Component={GereAnnonceAdminPage} />
                <Route path='/Admin/Annonce/detail/:id' Component={DetailAdminAnnoncePage} />
                <Route path='/Admin/AjouterAnnonce' Component={AjouterAdminAnnoncesPage} />
                <Route path='/Admin/Annonce/AjouterPlus/:id' Component={AddMoreAnnonceAdminPage} />
                <Route path='/Admin/Annonce/Editor/:id' Component={EditorAnnonceAdminPAge} />

                <Route path='/Admin/Ressources' Component={RessourceAdminPage} />
                <Route path='/Admin/AjouteRessources' Component={AjouteRessourcesAdminPAge} />
                <Route path='/Admin/Ressources/Editor/:id' Component={EditeRessourcePage} />
                <Route path='/Admin/Ressources/Detail/:id' Component={DetailResourceAdminPAge} />
                <Route path='/Admin/Ressources/AjoutPlus/:id' Component={AddMoreRessourcePAge} />

                <Route path='/Admin/Contact' Component={CoordonnerPhysiquePage} />
                <Route path='/Admin/Contact/ModifierContact/:id' Component={ModifierContactPage} />
                <Route path='/Admin/SocialContact' Component={ReseauxSocialPage} />
                <Route path='/Admin/Contact/HoraireOuverture' Component={HoraireOuverturePage} />
                <Route path='/Admin/Contact/HoraireOuverture/ModifierHoraire' Component={ModifierHorairePage} />
                <Route path='/Admin/SuggestionContact' Component={SuggestionPage} />

                <Route path='/Admin/Members' Component={AfficheMembrePage} />
                <Route path='/Admin/AjouterMembers' Component={AjouteMembresPage} />
                <Route path='/Admin/Members/Modifier/:id' Component={ModifierMembresComponent} />


                <Route path='/Admin/Profil' Component={Profile} />
                <Route path='/Admin/Profil/Modifier' Component={ModifierProfil} />
                <Route path='/Admin/StandyBuy/Detail/:id' Component={StandBuyDetailComponent} />
                <Route path="*" Component={PageNotFound} />

              </Routes>
              <CopyRight />
            </div>


          </div>
        </div>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  ) : (


    <>
      <Router>
        <div className='w-[100%] bg-white h-[100vh] overflow-hidden  font-serif'>
          <NavBarsVisitors />
          <div className="h-[90vh] bg-white overflow-x-hidden overflow-y-auto">
            <Routes>
              <Route path='/' Component={HomeVisitorPage} />
              <Route path='/Visit/Evenement' Component={EvenemntVisitorPage} />
              <Route path='/Visit/Evenement/Detail/:idc' Component={DetailEventVisitoPage} />
              <Route path='/Visit/Temoignage' Component={TemoignagePage} />
              <Route path='/Visit/NouvelleTemoignage' Component={FormulaireTemoignVisitores} />
              <Route path='/Visit/Annonce' Component={AnnoncePage} />
              <Route path='/Visit/Annonce/Detail/:idc' Component={DetailAnnoncePage} />
              <Route path='/Visit/Ressource' Component={RessourcePage} />
              <Route path='/Visit/Ressource/Detail/:idc' Component={DetailResourcePage} />
              <Route path='/Visit/Contact' Component={Contact} />
              <Route path='/Visit/Apropos' Component={AproposPage} />
              <Route path='/Login' Component={LoginWithProps} />
              <Route path='/regis' Component={RegisterAdmin} />
              <Route path="*" Component={PageNotFound} />
            </Routes>
            <FooterVisitor />
          </div>
          <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </Router>
    </>

  )
}
export default App;
