import React from 'react'
import NavBarsTemoignage from '../TemoignanegNavBars/NavBarsTemoignage'
import StandByDetail from './StandByDetail'
function StandBuyDetailComponent() {
    return (
        <>
            <NavBarsTemoignage />
            <StandByDetail />
        </>
    )
}

export default StandBuyDetailComponent
