/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import { dechiffrement, transformDate } from '../../../module';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';

function DetailAnnonce() {

    const { idc } = useParams();
    const id = dechiffrement(idc)
    const [event, setEvent] = useState({});
    const [photo, setPhoto] = useState([]);
    const [isPending, setPending] = useState(true)

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getonanonce/${id}`).then((response) => {
            setEvent(response.data);
            setPhoto(response.data.annoncesfiles);
            setTimeout(() => {
                setPending(false)
            }, 100)
        });
    }, [id]);

    const [mobile, SetMobile] = useState(window.innerWidth < 935)

    useEffect(() => {

        const hundleSize = () => {
            SetMobile(window.innerWidth < 935)
        }

        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }

    }, [])



    const [loadingImages, setLoadingImages] = useState([]);
    const [loadingImage, setLoadingImage] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    return (

        <>
            {isPending && <Loading />}
            <div className='w-ful'>
                <Link to='/Visit/Annonce' className='flex h-8 items-center ml-5 bg-orange-500 text-white w-max p-2 mt-4 rounded'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-return-left mr-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <p>Retour</p>
                </Link>
            </div>
            {event ? (
                <div className='flex justify-center items-center mt-2'>
                    <div className={` px-[20px]  bg-gray-100 rounded  w-[95%] p-1`}>
                        <h2 className='font-semibold  w-full'>{event.titre}</h2>
                        <span className='font-sans text-[13px] text-orange-700'>{transformDate(event.updatedAt)}</span>
                        <div className='flex items-center flex-col w-full  p-1'>
                            <div className={`m-[2px]  ${mobile ? 'w-full h-full' : 'flex justify-center items-center h-screen'} border border-orange-500  bg-white rounded overflow-hidden`}>
                                {event.file && (() => {
                                    const extension = event.file.split('.').pop();
                                    if (extension === 'pdf') {

                                        return (
                                            <div>PDF introuvable</div>
                                        );
                                    } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                        return (

                                            <>
                                                <div key={event.id} className='relative border h-full w-full '>

                                                    {loadingImage && (
                                                        <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                    )}

                                                    <ReactPlayer
                                                        url={`https://irakoze.burundientempsreel.com/uploads/annonces/${event.file}`}
                                                        controls
                                                        className=" inline object-cover object-center w-full h-full"
                                                        onReady={() => {
                                                            setTimeout(() => {
                                                                setLoadingImage(false);
                                                            }, 1000);
                                                        }}
                                                    />


                                                </div>
                                            </>


                                        );
                                    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                        return (
                                            <div className='relative h-full w-full'>
                                                {loadingImage && (
                                                    <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                )}

                                                <img
                                                    src={`https://irakoze.burundientempsreel.com/uploads/annonces/${event.file}`}
                                                    alt={`Image de ${event.titre}`}
                                                    className={`w-full h-full`} // Masquer l'image pendant le chargement
                                                    onLoad={() => {
                                                        setTimeout(() => {
                                                            setLoadingImage(false);
                                                        }, 1000);
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })()}

                            </div>
                            <div className=" mt-1 p-3 w-full flex justify-center">
                                <div className={` ${mobile ? 'w-[100%] text-[17px]' : 'w-[85%] text-[20px]'} text-gray-700`}>
                                    <div className=''>
                                        {event.description}
                                    </div>
                                </div>

                            </div>
                            {(photo.length > 0) ? (
                                <> <div className={`w-full ${mobile ? 'text-[15px]' : 'text-[22px]'} text-left  text-bold mt-10 text-gray-700`}>Les photos ou vidéo supplémentaire</div>

                                    <div className="w-full flex flex-wrap ">
                                        {photo.map((photos) => {
                                            return (
                                                <div className="hoverHideeDelet relative w-[15em] border border-orange-600 rounded-3xl h-[15em] bg-white overflow-hidden m-2">
                                                    {photos.file && (() => {
                                                        const extension = photos.file.split('.').pop();
                                                        if (extension === 'pdf') {

                                                            return (
                                                                <div>PDF introuvable</div>
                                                            );
                                                        } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                            return (

                                                                <>
                                                                    <div key={photos.id} className='relative border h-full w-full '>
                                                                        {loadingImages[index] ? (
                                                                            <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                        ) :
                                                                            (
                                                                                <ReactPlayer
                                                                                    url={`https://irakoze.burundientempsreel.com/uploads/annonces/${photos.file}`}
                                                                                    controls
                                                                                    className=" inline object-cover object-center w-full h-full"

                                                                                />
                                                                            )}

                                                                    </div>
                                                                </>


                                                            );
                                                        } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                            return (
                                                                <div key={photos.id} className='relative  h-full w-full '>
                                                                    {loadingImages[index] ? (
                                                                        <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                    ) :
                                                                        (
                                                                            <LoadingImage
                                                                                src={`https://irakoze.burundientempsreel.com/uploads/annonces/${photos.file}`}
                                                                                className="w-full h-full border-none object-contain"
                                                                                onLoad={() => handleImageLoad(index)}
                                                                            />
                                                                        )}

                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })()}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (<div>Erreur</div>)}

        </>
    )
}

export default DetailAnnonce
