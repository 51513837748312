import React, { useEffect, useState } from 'react'
import App from './App'
import Loading from './component/Loading/Loading';
function Apps() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        });

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading && <Loading />}
            <App />
        </>
    )
}

export default Apps;