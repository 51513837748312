/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";
import PropTypes from 'prop-types'

function AffchageMembre() {
  const [listoffmembers, setListMembers] = useState([]);
  const [element, setElement] = useState();
  const [pending, setPending] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (membre) => {
    const selectedItemsPerPage = parseInt(membre.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };
  const firstEntry = (currentPage - 1) * itemsPerPage + 1;
  const lastEntry = Math.min(currentPage * itemsPerPage, element);

  useEffect(() => {
    axios
      .get(
        `https://irakoze.burundientempsreel.com/api/members?page=${currentPage}&size=${itemsPerPage}`
      )
      .then((response) => {
        setElement(response.data.totalItems);
        setListMembers(response.data.members);
        setTotalPages(response.data.totalPages);
        setTimeout(() => {
          setPending(false)
        }, 1000);
      });
  }, [currentPage, itemsPerPage]);

  function handleDelete(id, position) {
    Swal.fire({
      title: "Confirmation",
      text: "Etes-vous sûr que vous voulez supprimer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "SUPPRIMER",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "swal-confirm-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://irakoze.burundientempsreel.com/api/membres/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success("le membre a été supprimé avec succès");
              setCurrentPage(1);
              const allMember = [...listoffmembers];
              allMember.splice(position, 1);
              setListMembers(allMember);
            } else {
              toast.error(
                "Une erreur s'est produite lors de la suppression du membre"
              );
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(
              "Une erreur s'est produite lors de la suppression du fichier"
            );
          });
      }
    });
  }

  const [tel, settel] = useState("");
  const [membreSearch, setmembreSearch] = useState([]);
  const [searching, setSearching] = useState(false);
  const search = (tel) => {
    if (tel) {
      setSearching(true); // Indiquer que la recherche est en cours
      axios
        .get("https://irakoze.burundientempsreel.com/api/membre/bytel", { params: { tel: tel } })
        .then((response) => {
          if (response.data === "événement introuvable !") {
            toast.error("membre de l'ecole introuvable", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            membreSearch.length = 0
            settel("")
          } else {
            setmembreSearch(response.data);
          }
          setSearching(false); // Indiquer que la recherche est terminée
        })
        .catch((err) => {
          console.error("membre not found!");
          setSearching(false); // Indiquer que la recherche est terminée
        });
    } else {
      membreSearch.length = 0;
    }
  };

  const [loading, SetLoading] = useState(true)

  const [loadingImages, setLoadingImages] = useState([]);

  const handleImageLoad = (index) => {

    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      updatedLoadingImages[index] = true;
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 50000);
      return updatedLoadingImages;
    });

  };

  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  const [mobile, SetMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 600)
    }
    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])

  return (
    <div class="bg-red-500 border-t text-gray-700 border-b sm:border-l sm:border-r flex sm:rounded-xl shadow mb-6">
      {pending && <Loading />}
      {listoffmembers.length > 0 ? (
        <div class="container mx-auto px-4 sm:px-8">
          <div class="py-8">
            <div>
              <h2 class="text-2xl font-semibold  text-yellow-500 leading-tight">
                Membre de LENGA
              </h2>
            </div>
            <div class="my-2 flex sm:flex-row flex-col">
              {membreSearch.length === 0 && (
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div className="relative">
                    <select
                      className="appearance-none h-full rounded-l-2xl border block w-full bg-white border-gray-400 text-gray-700 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option>5</option>
                      <option>10</option>
                      <option>20</option>
                      {listoffmembers.length > 20 && (
                        <option>{listoffmembers.length}</option>
                      )}
                    </select>
                    <div className="pointer-membres-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4 pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>

                  <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
              <div className="pr-10 bg-gray-50 p-1 m-1 rounded">
                <p className="text-gray-500 font-serif">
                  Rechercher par telephone
                </p>
                <div className="search py-1">
                  <form>
                    <input
                      type="text"
                      className="p-1 border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-orange-500"
                      id=""
                      placeholder="Rechercher..."
                      value={tel}
                      onChange={(e) => {
                        settel(e.target.value);
                        search(e.target.value);
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto wid">
              <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table class="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Student profile
                      </th>
                      <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Email
                      </th>
                      <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Tel
                      </th>
                      <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Fonction
                      </th>
                      <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {membreSearch.length > 0
                      ? membreSearch.map((value, position) => {
                        return (
                          <tr>
                            <td class="px-5 py-5 border-b relative overflow-hidden border-gray-200 bg-white text-sm">
                              <div class="flex items-center">
                                <div key={position} class="flex-shrink-0 w-10 h-10 overflow-hidden relative">
                                  {loadingImages[position] ? (
                                    <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                  ) :
                                    (
                                      <LoadingImage
                                        src={`hhttps://irakoze.burundientempsreel.com/uploads/membres/${value.file}`}
                                        className="w-full h-full border-none object-contain"
                                        onLoad={() => handleImageLoad(position)}
                                      />
                                    )}

                                </div>
                                <div class="ml-3">
                                  <p class="text-gray-900 whitespace-no-wrap">
                                    {value.nom + "  " + value.prenom}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.email}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.tel}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.fonction}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <td class="Action">
                                <span class="contIcon">
                                  <div class=" w-[7em] h-[3em]">
                                    <Link
                                      to={`/Admin/Members/Modifier/${value.id}`}
                                      class="link bi bi-pencil-square p-2 m-1 hover:bg-green-600 bg-green-400 transition-all rounded text-stone-50"
                                      title="Modifier"
                                    ></Link>
                                    <button
                                      onClick={() =>
                                        handleDelete(value.id, position)
                                      }
                                      className="link bi bi-trash3-fill p-2 m-1 hover:bg-red-600 rounded bg-red-400 text-white"
                                      title="Supprimer"
                                    >
                                      <i class=""></i>
                                    </button>{" "}
                                  </div>
                                </span>
                              </td>
                            </td>
                          </tr>
                        );
                      })
                      : listoffmembers &&
                      listoffmembers.map((value, position) => {
                        return (
                          <tr>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div class="flex items-center">
                                <div key={position} class="flex-shrink-0 w-10 h-10 overflow-hidden relative">
                                  {loadingImages[position] ? (
                                    <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                  ) :
                                    (
                                      <LoadingImage
                                        src={`https://irakoze.burundientempsreel.com/uploads/membres/${value.file}`} className="w-full h-full border-none object-contain"
                                        onLoad={() => handleImageLoad(position)}
                                      />
                                    )}

                                </div>
                                <div class="ml-3">
                                  <p class="text-gray-900 whitespace-no-wrap">
                                    {value.nom + "  " + value.prenom}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.email}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.tel}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.fonction}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <td class="Action">
                                <span class="contIcon">
                                  <div class=" w-[7em] h-[3em]">
                                    <Link
                                      to={`/Admin/Members/Modifier/${value.id}`}
                                      class="link bi bi-pencil-square p-2 m-1 hover:bg-green-600 bg-green-400 transition-all rounded text-stone-50"
                                      title="Modifier"
                                    ></Link>
                                    <button
                                      onClick={() =>
                                        handleDelete(value.id, position)
                                      }
                                      className="link bi bi-trash3-fill p-2 m-1 hover:bg-red-600 rounded bg-red-400 text-white"
                                      title="Supprimer"
                                    >
                                      <i class=""></i>
                                    </button>{" "}
                                  </div>
                                </span>
                              </td>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {membreSearch.length === 0 && (
                  <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                    <span className="text-xs xs:text-sm text-gray-900">
                      Affichage de {firstEntry} à {lastEntry} sur {element}{" "}
                      membres
                    </span>

                    <div className="inline-flex mt-2 xs:mt-0">
                      <button
                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Précédent
                      </button>
                      <button
                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (

        <div class={`${mobile ? 'w-[98%] p-1' : 'p-32'} h-[70vh] bg-slate-100 rounded-2x flex justify-center items-center`}>
          <div class={`${mobile ? 'w-[95%]' : ''} flex flex-wrap justify-between items-center mx-auto bg-orange-600 rounded-md `}>
            <div class={`text-center  py-20 px-2 border-dashed border-2 border-slate-200 rounded-xl  w-full `}>
              <i class='bi bi-contact bx-lg mb-5 '></i>
              <p class="text-xl mb-2 uppercase font-bold ">Pas de membre de l'école disponible</p>
              <spam class="text-m text-white block mb-10 dark:text-slate-50">Commencez par la créer un membre de l'école si est disponible</spam>
              <Link to="/Admin/AjouterMembers" class="bg-orange-400 rounded-full px-5 py-3 text-white hover:bg-orange-500 w-52">Ajouter</Link>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default AffchageMembre;
