import React from 'react'
import StandBuyAdmin from './StandBuyAdmin'
import NavBarsTemoignage from '../TemoignanegNavBars/NavBarsTemoignage'
function StandBuyAdminComponent() {
    return (
        <>
            <NavBarsTemoignage />
            <StandBuyAdmin />
        </>
        
    )
}

export default StandBuyAdminComponent
