import React from 'react'
import { Link } from 'react-router-dom'

function ReseauxSocial() {
    return (
        <div className='w-full h-full bg-white p-3 flex justify-center items-center'>
            <div className="bg-white  w-full h-max border border-orange-700 rounded-2xl  p-2">
                <h2 className='text-[25px] text-center animeBorder'>Réseaux social</h2>
                <div className="w-full h-max">
                    <p className='text-[18px] leading-[3em]'><b><i class="bi bi-facebook text-blue-700  p-1 text-[20px]"></i></b>: Lyée d'Excellence NGAGARA</p>
                    <p className='text-[18px] leading-[3em]'><b><i class="bi bi-twitter text-blue-500  p-1 text-[20px]"></i></b>: lycenagagraExcellence</p>
                    <p className='text-[18px] leading-[3em]'><b><i class="bi bi-envelope text-red-500  p-1 text-[20px]"></i></b>: lycenagagraExcellence@gmail.com</p>
                </div>
                <div className="w-full flex  rounded  justify-end  pr-2">
                    <Link to='/Admin/Contact/Modifier' className='p-1 bg-orange-400 opacity-30 pointer-events-none text-white hover:bg-orange-600 rounded transition-all  cursor-pointer w-max'> Modifier</Link>
                </div>
            </div>

        </div>
    )
}

export default ReseauxSocial
