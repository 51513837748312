import React from 'react'

function CopyRight() {
    return (
        <div className=" flexCopyRign p-4 bg-[rgba(10,1,67,0.93)] flex  w-full text-center justify-center items-center text-white">
            <div className="">Copyright ,developpée par la sociéte</div> <div className="w-[40px]  p-1 h-[40px] mx-2  border-4  border-double border-orange-600 rounded-full"><img src="https://irakoze.burundientempsreel.com/uploads/Photo/btr.png" alt="" className='w-full h-full object-cover' /></div> <div className="">Burundi en temps réel</div>
        </div>
    )
}

export default CopyRight