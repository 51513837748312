import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function MembrsNavBars() {
    const { pathname } = useLocation()
    let AdminMembre = /^\/Admin\/Members.*/
    let AdminMembreAjouter = /^\/Admin\/AjouterMembers.*/
    return (
        <div className='bg-gray-200 w-full pt-2 flex justify-around sticky top-0 z-10'>
            {AdminMembre.test(pathname) ? (
                <Link to='/Admin/Members' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-list text-[20px] mr-4"></i>
                    <p className='sizeText'>Listes</p>
                </Link>
            ) : (
                <Link to='/Admin/Members' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-list text-[20px] mr-4"></i>
                    <p className='sizeText'>Listes</p>
                </Link>
            )}

            {AdminMembreAjouter.test(pathname) ? (
                <Link to='/Admin/AjouterMembers' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-plus-circle text-[20px] mr-4"></i>
                    <p className='sizeText'>Ajouter</p>
                </Link>
            ) : (
                <Link to='/Admin/AjouterMembers' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class="bi bi-plus-circle text-[20px] mr-4"></i>
                    <p className='sizeText'>Ajouter</p>
                </Link>
            )}
        </div>
    )
}

export default MembrsNavBars
