import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function ModifierProfil() {
    const [isNom, SetisNom] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const nomRef = useRef(null)

    const [isPrenom, SetisPrenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const prenomRef = useRef(null)

    const [isEmail, SetisEmail] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const emailRef = useRef(null)

    const [isTel, SetisTel] = useState('')
    const [animationClassTel, setAnimationClassTel] = useState('');
    const telRef = useRef(null)

    const [isPass, SetisPass] = useState('')
    const [animationClassisPass, setAnimationClassPass] = useState('');
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const passRef = useRef(null)


    const [isPassConfirm, SetisPassConfirm] = useState('')
    const [animationClassisConfirm, setAnimationClassConfirm] = useState('');
    const passConfirmRef = useRef(null)

    const [radioValue, setRadioValue] = useState('');

    const [isProfil, SetisProfil] = useState('')



    const handleSubmit = (e) => {
        if (isNom.trim() == '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            nomRef.current.focus()
            e.preventDefault()
            return false
        } else if (isPrenom.trim() == '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            prenomRef.current.focus()
            e.preventDefault()
            return false
        }
        else if (isTel.trim() == '') {
            toast.warning('Le numero de téléphone est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassTel(' ')
            }, 3000)
            telRef.current.focus()
            e.preventDefault()
            return false
        }
        else if (isEmail.trim() == '') {
            toast.warning("L'address email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            emailRef.current.focus()
            e.preventDefault()
            return false
        }
        else if (!isEmail.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            emailRef.current.focus()
            e.preventDefault()
            return false
        }
        else if (isPass.trim() == '') {
            toast.warning('Le  password est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPass(' ')
            }, 3000)
            passRef.current.focus()
            e.preventDefault()
            return false
        }
        else if (!isPass.trim().match(PasswordRegex)) {
            toast.error(`Le mot de passe est incorrect ,
            Veuillez choisir un mot de passe qui respecte les critères suivants:
                         -Doit contenir au moins une lettre minuscule (a-z)
                         -Doit contenir au moins une lettre majuscule(A - Z)
                         - Doit contenir au moins un chiffre(0 - 9)
                         -Doit contenir au moins un caractère spécial parmi les suivants : !@#$ %^&* (),.?":{}|<>!!`, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPass(' ')
            }, 3000)
            passRef.current.focus()
            e.preventDefault()
            return false
        }
        else if (isPassConfirm.trim() == '') {
            toast.warning('Confirmer le mot de passe', {
                autoClose: 2000
            });
            setAnimationClassConfirm('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassConfirm(' ')
            }, 3000)
            passConfirmRef.current.focus()
            e.preventDefault()
            return false
        } else if (isPassConfirm.trim() != isPass.trim()) {
            toast.error('La confirmation de mot de passe est incorrect,Veuillez reessayer encore !!', {
                autoClose: 5000
            });
            setAnimationClassConfirm('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassConfirm(' ')
            }, 3000)
            passConfirmRef.current.focus()
            e.preventDefault()
            return false
        } else if (radioValue === '') {

            toast.warning('Veuillez sélectionner votre genre', {
                autoClose: 2000
            });
            e.preventDefault()
            return false
        }
        else if (isProfil == '') {

            toast.warning('Veuillez sélectionner votre photos de profil', {
                autoClose: 2000
            });
            e.preventDefault()
            return false
        }
    }


    const sendRef = useRef(null)
    useEffect(() => {
        const handleKeyPress = (event) => {

            const x = event.charCode;
            if (x == 13) {
                sendRef.current.click()
            }

            return false
        };
        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);

        };


    }, []);
    return (
        <div className=' bg-white  h-max flex justify-center items-center'>
            <div className="bg-white temoign m-3 p-2 rounded w-[35em] h-max wimax ">
                <h2 className=' p-2 text-center  text-[30px] text-orange-500'>Modifier</h2>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col mb-6  p-1">
                        <div className="flex w-full justify-between responsivLogin my-3">
                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={nomRef} value={isNom} onInput={(e) => SetisNom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Nom
                                </label>
                            </div>

                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={prenomRef} value={isPrenom} onInput={(e) => SetisPrenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Prénom
                                </label>
                            </div>
                        </div>

                        <div className="flex w-full justify-between responsivLogin ">

                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={telRef} value={isTel} onInput={(e) => SetisTel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Téléphone
                                </label>
                            </div>

                            <div class="relative h-11 w-1/2 bloksInput mr-2 ">
                                <input ref={emailRef} value={isEmail} onInput={(e) => SetisEmail(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Email
                                </label>
                            </div>
                        </div>
                        <div className="relative top-6">
                            <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
                            <label htmlFor="profils" className='transition-all p-1 bg-green-400 hover:bg-green-600 rounded cursor-pointer text-white'>Selection le profile</label>
                            <input type="file" id='profils' hidden accept='image/*' onChange={(e) => { const url = URL.createObjectURL(e.target.files[0]); SetisProfil(url) }} />
                            <div className={`animeBorder p-1 transition-all relative  top-4 littleWidth rounded border-4 border-dotted border-orange-500 w-[300px] ${isProfil == '' ? 'h-[0px] opacity-0 pointer-events-none' : 'h-[200px] opacity-100 pointer-events-none'}`}>
                                <img src={isProfil} alt="" className='w-full h-full rounded object-cover' />
                            </div>
                        </div>
                    </div>
                    <div className="mt-16  flex justify-end">
                        <div className="flex justify-end items-center ">
                            <Link to="/Admin/profil" for="send" ref={sendRef} className="w-max  flex justify-end p-1 "><i title="Retourner " class="bi bi-arrow-return-left bg-green-600 rounded cursor-pointer px-5 py-1 text-white"></i></Link>
                        </div>
                        <div className="flex justify-end items-center">
                            <label for="send" ref={sendRef} className="w-max  flex justify-end p-1 "><i title="Envoyer" class="bi bi-send bg-orange-600 rounded cursor-pointer px-5 py-1 text-white"></i></label>
                            <input type="submit" id="send" hidden></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModifierProfil




// import React, { useEffect, useRef, useState } from 'react';
// import { toast } from 'react-toastify';

// function ModifierProfil() {
//     const [isNom, setIsNom] = useState('');
//     const [animationClassNom, setAnimationClassNom] = useState('');
//     const nomRef = useRef(null);

//     const [isPrenom, setIsPrenom] = useState('');
//     const [animationClassPrenom, setAnimationClassPrenom] = useState('');
//     const prenomRef = useRef(null);

//     const [isEmail, setIsEmail] = useState('');
//     const [animationClassEmail, setAnimationClassEmail] = useState('');
//     const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
//     const emailRef = useRef(null);

//     const [isTel, setIsTel] = useState('');
//     const [animationClassTel, setAnimationClassTel] = useState('');
//     const telRef = useRef(null);

//     const [isPass, setIsPass] = useState('');
//     const [animationClassPass, setAnimationClassPass] = useState('');
//     const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
//     const passRef = useRef(null);

//     const [isPassConfirm, setIsPassConfirm] = useState('');
//     const [animationClassConfirm, setAnimationClassConfirm] = useState('');
//     const passConfirmRef = useRef(null);

//     const [radioValue, setRadioValue] = useState('');

//     const [isProfil, setIsProfil] = useState('');

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         if (isNom.trim() === '') {
//             toast.warning('Le nom est obligatoire', {
//                 autoClose: 2000
//             });
//             setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-yellow-500');
//             nomRef.current.focus();
//             return;
//         }

//         if (isPrenom.trim() === '') {
//             toast.warning('Le prénom est obligatoire', {
//                 autoClose: 2000
//             });
//             setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-yellow-500');
//             prenomRef.current.focus();
//             return;
//         }

//         if (isTel.trim() === '') {
//             toast.warning('Le numéro de téléphone est obligatoire', {
//                 autoClose: 2000
//             });
//             setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-yellow-500');
//             telRef.current.focus();
//             return;
//         }

//         if (isEmail.trim() === '') {
//             toast.warning("L'adresse email est obligatoire", {
//                 autoClose: 2000
//             });
//             setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500');
//             emailRef.current.focus();
//             return;
//         }

//         if (!isEmail.trim().match(emailRegex)) {
//             toast.error("L'adresse email est incorrecte", {
//                 autoClose: 2000
//             });
//             setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500');
//             emailRef.current.focus();
//             return;
//         }

//         if (isPass.trim() === '') {
//             toast.warning('Le mot de passe est obligatoire', {
//                 autoClose: 2000
//             });
//             setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500');
//             passRef.current.focus();
//             return;
//         }

//         if (!isPass.trim().match(PasswordRegex)) {
//             toast.error('Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial', {
//                 autoClose: 2000
//             });
//             setAnimationClassPass('animate__animated animate__shakeX placeholder-shown:border-red-500');
//             passRef.current.focus();
//             return;
//         }

//         if (isPassConfirm.trim() === '') {
//             toast.warning('Veuillez confirmer votre mot de passe', {
//                 autoClose: 2000
//             });
//             setAnimationClassConfirm('animate__animated animate__shakeX placeholder-shown:border-red-500');
//             passConfirmRef.current.focus();
//             return;
//         }

//         if (isPass !== isPassConfirm) {
//             toast.error('Les mots de passe ne correspondent pas', {
//                 autoClose: 2000
//             });
//             setAnimationClassConfirm('animate__animated animate__shakeX placeholder-shown:border-red-500');
//             passConfirmRef.current.focus();
//             return;
//         }

//         if (radioValue === '') {
//             toast.warning('Veuillez sélectionner votre genre', {
//                 autoClose: 2000
//             });
//             return;
//         }

//         if (isProfil.trim() === '') {
//             toast.warning('Veuillez sélectionner votre photo de profil', {
//                 autoClose: 2000
//             });
//             return;
//         }

//         // Toutes les validations sont réussies, vous pouvez soumettre les données
//         toast.success('Profil modifié avec succès', {
//             autoClose: 2000
//         });

//         // Réinitialise les champs après la soumission réussie
//         setIsNom('');
//         setIsPrenom('');
//         setIsTel('');
//         setIsEmail('');
//         setIsPass('');
//         setIsPassConfirm('');
//         setRadioValue('');
//         setIsProfil('');
//     };

//     useEffect(() => {
//         // Pour effacer les animations de secousse lorsque l'utilisateur commence à taper
//         const handleKeyDown = () => {
//             setAnimationClassNom('');
//             setAnimationClassPrenom('');
//             setAnimationClassTel('');
//             setAnimationClassEmail('');
//             setAnimationClassPass('');
//             setAnimationClassConfirm('');
//         };

//         window.addEventListener('keydown', handleKeyDown);
//         return () => window.removeEventListener('keydown', handleKeyDown);
//     }, []);

//     return (
//         <div className="container mx-auto">
//             <h1 className="text-3xl font-bold mb-8">Modifier le profil</h1>
//             <form onSubmit={handleSubmit}>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="nom">
//                         Nom
//                     </label>
//                     <input
//                         id="nom"
//                         type="text"
//                         placeholder="Votre nom"
//                         className={`border rounded p-2 w-full focus:outline-none ${animationClassNom}`}
//                         ref={nomRef}
//                         value={isNom}
//                         onChange={(e) => setIsNom(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="prenom">
//                         Prénom
//                     </label>
//                     <input
//                         id="prenom"
//                         type="text"
//                         placeholder="Votre prénom"
//                         className={`border rounded p-2 w-full focus:outline-none ${animationClassPrenom}`}
//                         ref={prenomRef}
//                         value={isPrenom}
//                         onChange={(e) => setIsPrenom(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="tel">
//                         Numéro de téléphone
//                     </label>
//                     <input
//                         id="tel"
//                         type="text"
//                         placeholder="Votre numéro de téléphone"
//                         className={`border rounded p-2 w-full focus:outline-none ${animationClassTel}`}
//                         ref={telRef}
//                         value={isTel}
//                         onChange={(e) => setIsTel(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="email">
//                         Adresse email
//                     </label>
//                     <input
//                         id="email"
//                         type="email"
//                         placeholder="Votre adresse email"
//                         className={`border rounded p-2 w-full focus:outline-none ${animationClassEmail}`}
//                         ref={emailRef}
//                         value={isEmail}
//                         onChange={(e) => setIsEmail(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="pass">
//                         Mot de passe
//                     </label>
//                     <input
//                         id="pass"
//                         type="password"
//                         placeholder="Votre mot de passe"
//                         className={`border rounded p-2 w-full focus:outline-none ${animationClassPass}`}
//                         ref={passRef}
//                         value={isPass}
//                         onChange={(e) => setIsPass(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="passConfirm">
//                         Confirmer le mot de passe
//                     </label>
//                     <input
//                         id="passConfirm"
//                         type="password"
//                         placeholder="Confirmez votre mot de passe"
//                         className={`border rounded p-2 w-full focus:outline-none ${animationClassConfirm}`}
//                         ref={passConfirmRef}
//                         value={isPassConfirm}
//                         onChange={(e) => setIsPassConfirm(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg">Genre</label>
//                     <div>
//                         <label className="inline-flex items-center mr-4">
//                             <input
//                                 type="radio"
//                                 name="genre"
//                                 value="homme"
//                                 className="form-radio h-5 w-5 text-blue-600"
//                                 checked={radioValue === 'homme'}
//                                 onChange={(e) => setRadioValue(e.target.value)}
//                             />
//                             <span className="ml-2">Homme</span>
//                         </label>
//                         <label className="inline-flex items-center">
//                             <input
//                                 type="radio"
//                                 name="genre"
//                                 value="femme"
//                                 className="form-radio h-5 w-5 text-pink-600"
//                                 checked={radioValue === 'femme'}
//                                 onChange={(e) => setRadioValue(e.target.value)}
//                             />
//                             <span className="ml-2">Femme</span>
//                         </label>
//                     </div>
//                 </div>
//                 <div className="mb-4">
//                     <label className="block mb-2 text-lg" htmlFor="profil">
//                         Photo de profil
//                     </label>
//                     <input
//                         id="profil"
//                         type="file"
//                         accept="image/*"
//                         className="border rounded p-2 w-full"
//                         onChange={(e) => setIsProfil(e.target.value)}
//                     />
//                 </div>
//                 <button
//                     type="submit"
//                     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                 >
//                     Modifier
//                 </button>
//             </form>
//         </div>
//     );
// }

// export default ModifierProfil;
