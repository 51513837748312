import React from 'react'
import NavBarsAnnonces from '../NavBarsAnnonces/NavBarsAnnonces'
import AddMoreAnnonce from './AddMoreAnnonce'
function AddMoreAnnonceComponent() {
    return (
        <div>
            <NavBarsAnnonces />
            <AddMoreAnnonce />
        </div>
    )
}

export default AddMoreAnnonceComponent
