import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../../Loading/Loading';
import { RotateLoader } from 'react-spinners'

function Membres() {
    const [personnel, setpersonnel] = useState([]);
    const [isPending, setPending] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/personnelle').then((response) => {
            setpersonnel(response.data);
            setTimeout(() => {
                setPending(false)
            }, 100)
        }).catch((err) => {
            console.log(err.message)
        })
    }, []);
    return (
        <div className="w-full bg-white flex flex-col mt-[20px] mx-4 justify-center items-center border-2 border-[#00000017]">

            {isPending && <Loading />}
            {personnel.length > 0 ? (
                <>
                    <h2 className='text-[20px] px-4 p-1 my-2 rounded text-orange-400'>Les membres</h2>
                    <div className="flex flex-wrap justify-center">
                        {
                            personnel.map((value) => {

                                return (
                                    <div
                                        className="members border rounded flex overflow-hidden m-4 justify-center flex-col items-center w-max h-max p-2"
                                        key={value.id}
                                    >
                                        <div className="w-[10em] h-[10em] relative  members1 animeTop rounded-full overflow-hidden">
                                            {loading && <div className='absolute bg-gray-100 w-full h-full flex justify-center items-center'><RotateLoader /></div>}
                                            <img
                                                onLoad={() => {
                                                    setTimeout(() => {
                                                        setLoading(false)
                                                    }, 1000);
                                                }}
                                                src={`https://irakoze.burundientempsreel.com/uploads/membres/${value.file}`}
                                                alt=""
                                                className='w-full h-full object-cover'
                                            />
                                        </div>
                                        <div className="w-full mt-3">
                                            <p>Nom : {value.nom} </p>
                                            <p>Prenom: {value.prenom}</p>
                                            <p>Fonction: {value.fonction}</p>
                                            <p>Tel: {value.tel}</p>
                                            <p>Email: {value.email}</p>
                                        </div>
                                    </div>
                                );

                            })}
                    </div>
                </>
            ) : (
                <div></div>
            )}




        </div>
    );
}

export default Membres;
