import React from 'react'
import NavBarsContact from '../NavBarsContact/NavBarsContact'
import HorairesOuverutre from './HorairesOuverutre'
function HorairesOuverutreComponet() {
    return (
        <>
            <NavBarsContact />
            <HorairesOuverutre />
        </>
    )
}

export default HorairesOuverutreComponet
