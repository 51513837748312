import React from 'react'
import ModifierMembres from './ModifierMembres'
import MembrsNavBars from '../MembleNavBArs/MembrsNavBars'
function ModifierMembresComponent() {
  return (
    <div>
          <MembrsNavBars />
          <ModifierMembres />
    </div>
  )
}

export default ModifierMembresComponent
