import React from 'react'
import NavBarsContact from '../NavBarsContact/NavBarsContact'
import ReseauxSocial from './ReseauxSocial'
function ReseauxSocialCompoment() {
    return (
        <>
            <NavBarsContact />
            <ReseauxSocial />

        </>
    )
}

export default ReseauxSocialCompoment
