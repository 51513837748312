/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
function StandBuyAdmin() {
    const [temoignages, setTemoignages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isPending, setPending] = useState(true)
    useEffect(() => {
        axios
            .get(`https://irakoze.burundientempsreel.com/api/standbytemoignage?page=${currentPage}`)
            .then((response) => {
                const { temoignages, totalPages } = response.data;
                setTemoignages(temoignages);
                setTotalPages(totalPages);
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [currentPage]);


    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Fonction pour passer à la page précédente
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={
                        currentPage === i
                            ? 'bg-orange-700 w-[30px] h-[30px] flex justify-center items-center rounded-full text-white'
                            : 'bg-gray-200 hover:bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full'
                    }
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    function transformDate(isoDate) {
        const date = new Date(isoDate);
        const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const day = frenchDays[date.getDay()];
        const dayNumber = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        hours = hours > 9 ? hours : '0' + hours;

        let time = `${hours}h${minutes}`;
        return (
            <>
                <p className='mx-2'>
                    {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}
                </p>
                <p className='ml-2'>à {time}</p>
            </>
        );
    }

    return (
        <div className='bg-white min-h-[75vh] flex flex-col items-end'>
            {isPending && <Loading />}
            <div className='w-full h-max mb-5 bg-white p-1 flex flex-wrap justify-around'>
                {temoignages.length > 0 ? (
                    temoignages.map((value) => {
                        return (
                            <div className='bg-gray-200 w-[25em] p-2 m-1 rounded' key={value.id}>
                                <div className='trasition-all w-full hover:text-orange-500 mt-3'>
                                    <button className='text-center font-serif p-1 w-full h-full text-[18px] '>{value.titre}</button>
                                </div>
                                <Link to={`/Admin/StandyBuy/Detail/${value.id}`} className='text-gray-500 my-3'>
                                    {value.contenue && value.contenue.length > 300 ? value.contenue.slice(0, 300) + '...' : value.contenue}
                                </Link>
                                <div className='flex justify-between p-1 mt-5 font-serif'>
                                    <span>Nom: {value.nom}</span>
                                    <span>Prénom: {value.prenom}</span>
                                    <span>{transformDate(value.createdAt)}</span>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className='w-full h-[50vh] text-[25px] text-gray-400 flex justify-center items-center p-2'>Aucun témoignage en standby</div>
                )}
            </div>

            {temoignages.length > 0 && (
                <div className='w-full flex justify-center items-center '>
                    <div className='w-[25em] h-full flex justify-around items-center p-2'>
                        <button onClick={previousPage} disabled={currentPage === 1} className='transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded'>
                            <a href="#" className='mr-2'>&laquo;</a>
                            <p>Précédent</p>
                        </button>
                        <div className="pagination-link">
                            {generatePageNumbers()}
                        </div>
                        <button onClick={nextPage} disabled={currentPage === totalPages} className='transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded'>
                            <p>Suivant</p>
                            <a href="#" className='ml-2'>&raquo;</a>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StandBuyAdmin;
