import React from 'react'
import EvenementNavBars from '../EvenementNavNars/EvenementNavBars'
import EvenementAdimin from './EvenementAdimin'
function EvenementAdiminComponent() {
    return (
        <>
            <EvenementNavBars />
            <EvenementAdimin />
        </>
    )
}

export default EvenementAdiminComponent
