import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

function EvenementNavBars() {
    const { pathname } = useLocation()
    const Evenent = /^\/Admin\/Evenement.*/
    const AdminEventAjout = /^\/Admin\/AjoutEvenement.*/


    const [mobile2, SetMobile2] = useState(window.innerWidth < 366);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile2(window.innerWidth < 366)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    return (
        <div className='bg-gray-200 w-full pt-2 flex justify-around sticky top-0 z-10'>
            {Evenent.test(pathname) ? (
                <Link to='/Admin/Evenement' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-calendar4-event ${mobile2 ? ' mr-0 px-4' : ' mr-4'}  text-[20px]`}></i>
                    <p className={`sizeText ${mobile2 ? 'hidden' : 'block'}`}>Gérer événement</p>
                </Link>
            ) : (
                <Link to='/Admin/Evenement' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-calendar4-event ${mobile2 ? ' mr-0 px-4' : ' mr-4'}  text-[20px]`}></i>
                    <p className={`sizeText ${mobile2 ? 'hidden' : 'block'}`}>Gérer événement</p>
                </Link>
            )}
            {AdminEventAjout.test(pathname) ? (
                <Link to='/Admin/AjoutEvenement' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-folder-plus ${mobile2 ? ' mr-0 px-4' : ' mr-4'}  text-[20px]`}></i>
                    <p className={`sizeText ${mobile2 ? 'hidden' : 'block'}`}>Ajouter événement</p>
                </Link>
            ) : (
                <Link to='/Admin/AjoutEvenement' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                    <i class={`bi bi-folder-plus ${mobile2 ? ' mr-0 px-4' : ' mr-4'}  text-[20px]`}></i>
                    <p className={`sizeText ${mobile2 ? 'hidden' : 'block'}`}>Ajouter événement</p>
                </Link>
            )}
        </div>
    )
}

export default EvenementNavBars
