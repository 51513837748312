import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function NavBarsTemoignage() {
    let AdminTemoignage = /^\/Admin\/Temoignage.*/
    let AdminTemoignageStandBuy = /^\/Admin\/StandyBuy.*/

    const { pathname } = useLocation()
    return (
        <div className='bg-gray-200 w-full pt-2 flex justify-around sticky top-0 z-10'>
            {AdminTemoignage.test(pathname) ? (
                <Link to='/Admin/Temoignage' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>

                    <i class="bi bi bi-badge-tm  text-[20px] mr-2"></i>
                    <p className='sizeText'>Gérer témoignage</p>
                </Link>
            ) : (
                <Link to='/Admin/Temoignage' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>

                    <i class="bi bi bi-badge-tm  text-[20px] mr-2"></i>
                    <p className='sizeText'>Gérer témoignage</p>
                </Link>
            )}
            {AdminTemoignageStandBuy.test(pathname) ? (
                <Link to='/Admin/StandyBuy' className='mx-2   p-1 w-max border-b-2 border-blue-600  bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>

                    <i class="bi bi bi-badge-tm  text-[20px] mr-2"></i>
                    <p className='sizeText'>Standbuy</p>
                </Link>
            ) : (
                <Link to='/Admin/StandyBuy' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>

                    <i class="bi bi bi-badge-tm  text-[20px] mr-2"></i>
                    <p className='sizeText'>Standbuy</p>
                </Link>
            )}
        </div>
    )
}

export default NavBarsTemoignage
