import React from 'react'
import ModifierContact from './ModifierContact'
import NavBarsContact from '../NavBarsContact/NavBarsContact'
function ModifierContactComponent() {
  return (
    <>
      <NavBarsContact />
      <ModifierContact />
    </>
  )
}

export default ModifierContactComponent
