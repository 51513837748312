import React from 'react'
import NavBarsAnnonces from '../NavBarsAnnonces/NavBarsAnnonces'
import GereAnnonces from './GereAnnonces'
function GereAnnoncesComponent() {
    return (
        <div>
            <NavBarsAnnonces />
            <GereAnnonces />
        </div>
    )
}

export default GereAnnoncesComponent
