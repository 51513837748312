import React from 'react'
import NavBarsContact from '../NavBarsContact/NavBarsContact'
import CoordonnerPhusique from './CoordonnerPhusique'
function CoordonnerPhusiqueComponent() {
  return (
    <>
      <NavBarsContact />
      <CoordonnerPhusique />
    </>
  )
}

export default CoordonnerPhusiqueComponent
